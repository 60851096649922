import { request } from '../../utils';

export const AgodaApi = {
  // Get profile information
  getProfile: async (chatroomId, token) => {
    try {
      const response = await request.get(`/private/module/rpa/profile/${chatroomId}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      return response;
    } catch (error) {
      console.error('API Error in getProfile:', error);
      throw error;
    }
  },

  // Get user information
  getUserInfo: async (profileId) => {
    try {
      const response = await request.get(`/agoda/get_user_info_db/${profileId}`, {}, true);
      return response;
    } catch (error) {
      console.error('API Error in getUserInfo:', error);
      throw error;
    }
  },

  // Get source information
  getSourceInfo: async (userId) => {
    try {
      const response = await request.get(`/agoda/get_source_info_db/${userId}`, {}, true);
      return response;
    } catch (error) {
      console.error('API Error in getSourceInfo:', error);
      throw error;
    }
  },

  // Get conversation ID
  getConversationId: async (sourceId) => {
    try {
      const response = await request.get(`/agoda/get_conversation_id_db/${sourceId}`, {}, true);
      return response;
    } catch (error) {
      console.error('API Error in getConversationId:', error);
      throw error;
    }
  },

  // Get booking information
  getBookingInfo: async (conversationId) => {
    try {
      const response = await request.get(`/agoda/get_booking_info_db/${conversationId}`, {}, true);
      return response;
    } catch (error) {
      // console.error('API Error in getBookingInfo:', error);
      throw error;
    }
  },

  // Get conversation messages
  getConversationMessages: async (conversationId) => {
    try {
      const response = await request.get(`/agoda/get_conversation_message_db/${conversationId}`, {}, true);
      return response;
    } catch (error) {
      // console.error('API Error in getConversationMessages:', error);
      throw error;
    }
  }
}; 