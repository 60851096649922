import axios from 'axios';

const getToken = () => {
  return localStorage.getItem('token');
}

// 創建請求實例的工廠函數
const createRequestInstance = (isRpa = false) => {
  const baseURL = isRpa 
    ? 'https://rpa.scfg.io/'
    : process.env.REACT_APP_SERVER_URL;

  const instance = axios.create({
    baseURL,
    timeout: 20000,
  });

  // 請求攔截器
  instance.interceptors.request.use((config) => {
    const token = getToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    // 為 RPA 請求添加 X-API-KEY
    if (isRpa) {
      config.headers['X-API-KEY'] = 'sc90699098';
    }
    return config;
  }, (error) => {
    return Promise.reject(error);
  });

  // 響應攔截器
  instance.interceptors.response.use((response) => {
    return response.data;
  }, async (error) => {
    // 獲取原始請求配置
    const originalRequest = error.config;

    // 處理 401 未授權錯誤 (Unauthorized)
    if (error.response && error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        // 嘗試使用刷新令牌獲取新的訪問令牌
        const refreshToken = localStorage.getItem('refreshToken');
        if (refreshToken) {
          // 呼叫刷新令牌 API 並獲取新的訪問令牌
          // 這裡應該實現實際的刷新邏輯，例如:
          // const response = await axios.post('/auth/refresh', { refreshToken });
          // const newToken = response.data.token;
          // localStorage.setItem('token', newToken);
          
          // 使用新令牌更新原始請求的授權頭
          // originalRequest.headers.Authorization = `Bearer ${newToken}`;
          
          // 重試原始請求
          // return instance(originalRequest);
          
          // 暫時重定向到登入頁面，直到實現實際的刷新邏輯
          localStorage.removeItem('token');
          localStorage.removeItem('refreshToken');
          if (typeof window !== 'undefined') {
            window.location.href = '/login';
          }
        } else {
          // 沒有刷新令牌，重定向到登入頁面
          if (typeof window !== 'undefined') {
            window.location.href = '/login';
          }
        }
      } catch (refreshError) {
        // 處理刷新令牌錯誤
        localStorage.removeItem('token');
        localStorage.removeItem('refreshToken');
        if (typeof window !== 'undefined') {
          window.location.href = '/login';
        }
        return Promise.reject(refreshError);
      }
    }
    
    // 處理 418 I'm a teapot 錯誤 - 重定向到登入頁面
    if (error.response && error.response.status === 418) {
      // 清除認證令牌
      localStorage.removeItem('token');
      localStorage.removeItem('refreshToken');
      // 重定向到登入頁面
      if (typeof window !== 'undefined') {
        window.location.href = '/login';
      }
      return Promise.reject(error);
    }

    // 處理其他錯誤
    if (error.response) {
      error.response.data.status = error.response.status;
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  });

  return instance;
};

// 默認實例（使用 SERVER_URL）
const defaultRequest = createRequestInstance();

// RPA 實例（使用 RPA_URL）
const rpaRequest = createRequestInstance(true);

// 統一的請求函數
const request = {
  get: (url, config = {}, isRpa = false) => {
    return isRpa ? rpaRequest.get(url, config) : defaultRequest.get(url, config);
  },
  post: (url, data, config = {}, isRpa = false) => {
    return isRpa ? rpaRequest.post(url, data, config) : defaultRequest.post(url, data, config);
  },
  put: (url, data, config = {}, isRpa = false) => {
    return isRpa ? rpaRequest.put(url, data, config) : defaultRequest.put(url, data, config);
  },
  delete: (url, config = {}, isRpa = false) => {
    return isRpa ? rpaRequest.delete(url, config) : defaultRequest.delete(url, config);
  },
  patch: (url, data, config = {}, isRpa = false) => {
    return isRpa ? rpaRequest.patch(url, data, config) : defaultRequest.patch(url, data, config);
  }
};

export default request;
