import { request } from '../../../../utils';
import { message } from 'antd';
const SERVER_URL = process.env.REACT_APP_SERVER_URL;
const DEBUG_MODE = process.env.REACT_APP_DEBUG_MODE;

// ===== 認證相關 API =====
const AUTH_STORAGE_KEY = 'auth_config';
const AUTH_EXPIRY_TIME = 0.5 * 60 * 1000; // 5分鐘，單位為毫秒

const getStoredAuthConfig = () => {
  const storedData = localStorage.getItem(AUTH_STORAGE_KEY);
  if (!storedData) return null;
  
  try {
    const { accessToken, serverUrl, timestamp } = JSON.parse(storedData);
    const now = Date.now();
    
    // 檢查是否超過5分鐘
    if (now - timestamp > AUTH_EXPIRY_TIME) {
      localStorage.removeItem(AUTH_STORAGE_KEY);
      return null;
    }
    
    return { accessToken, serverUrl };
  } catch (error) {
    console.error('解析儲存的認證配置失敗:', error);
    localStorage.removeItem(AUTH_STORAGE_KEY);
    return null;
  }
};

const storeAuthConfig = (accessToken, serverUrl) => {
  const authData = {
    accessToken,
    serverUrl,
    timestamp: Date.now()
  };
  localStorage.setItem(AUTH_STORAGE_KEY, JSON.stringify(authData));
};

export const getAuthConfig = async (checkTime = true) => {
  try {
    if (checkTime) {
      // 先檢查 localStorage
    const storedConfig = getStoredAuthConfig();
    if (storedConfig) {
        return storedConfig;
      }
    }

    // 如果沒有有效的儲存配置，則重新獲取
    const debug = DEBUG_MODE === 'true' ? true : false;
    const [accessToken, serverUrl] = await Promise.all([
      getAccessToken(debug),
      getServerUrl(debug)
    ]);

    // 儲存新的配置到 localStorage
    storeAuthConfig(accessToken, serverUrl);

    return {
      accessToken,
      serverUrl
    };
  } catch (error) {
    console.error('獲取認證配置失敗:', error);
    // // throw new Error('Failed to fetch authentication configuration');
  }
};






export const getOfficialAuthConfig = async () => {
  try {
    const [accessToken, serverUrl] = await Promise.all([
      getOfficialAccessToken(),
      getOfficialServerUrl()
    ]);

    return {
      accessToken,
      serverUrl
    };
  } catch (error) {
    console.error('獲取認證配置失敗:', error);
    // // throw new Error('Failed to fetch authentication configuration');
  }
};

export const getServerUrl = async (debug) => {
  try {
    const response = await request.get(`${SERVER_URL}/private/property/ecommerce/profile?debug=${debug}`);
    return response.body.server_url;
    
  } catch (error) {
    console.log('error', error);
    
    if (error && error.status === 404 && debug === true) {
      console.warn('404 錯誤，將 debugMode 切換為 false 並重新嘗試...');
      message.warning('開發模式尚未開啟，請聯絡客服');
      return await getServerUrl(false); // 重新調用函數，使用 debug = false
    }
    console.error('獲取伺服器 URL 失敗:', error);
    // // throw new Error('Unable to fetch server URL');
  }
};

export const getAccessToken = async (debug) => {
  try {
    const response = await request.get(
      `${SERVER_URL}/private/property/ecommerce/jwt?debug=${debug}`
    );

    return response.access_token;
  } catch (error) {
    if (error && error.status === 404 && debug === true) {
      console.warn('404 錯誤，將 debugMode 模式關閉 並重新嘗試...');
      return await getAccessToken(false); // 重新調用函數，使用 debug = false
    }
    console.error('獲取存取令牌失敗:', error);
    // // throw new Error('Unable to fetch access token');
  }
};

export const getOfficialAccessToken = async () => {
  try {
    const response = await request.get(`${SERVER_URL}/private/property/official_site/jwt`);
    console.log('response', response.access_token);
    return response.access_token;
  } catch (error) {
    console.error('獲取存取令牌失敗:', error);
    // // throw new Error('Unable to fetch access token');
  }
};

export const getOfficialServerUrl = async () => {
  try {
    const response = await request.get(`${SERVER_URL}/private/property/official_site/profile`);
    console.log('response', response.body.server_url);
    
    return response.body.server_url;
  } catch (error) {
    console.error('獲取伺服器 URL 失敗:', error);
    // // throw new Error('Unable to fetch server URL');
  }
};