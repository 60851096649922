import React from 'react';
import { Button, Input, Tooltip, Spin, Upload } from 'antd';
import {
  CloseOutlined,
  CloudUploadOutlined,
  ReloadOutlined,
  LoadingOutlined,
} from '@ant-design/icons';

const { TextArea } = Input;
const { Dragger } = Upload;

// General component
export const General = ({
  uploading,
  text,
  setText,
  blobs,
  formatFileSize,
  removeFile,
  handleFileChange,
  fileInputRef,
  handleClickUploadMore,
  props,
  url,
  setUrl,
  depth,
  setDepth,
  reset,
  handleMemorize,
  fetching,
  memory,
  handleOpenModal,
  formatFileName,
  formatFileFormat,
  deleteMemory,
  handleFetchMemory,
}) => {

  return (
    <div className="flex flex-row w-full h-full">
      <div className="flex flex-col w-1/2 h-full justify-start border-r p-3 gap-4">
        <h1 className="text-lg font-bold text-textDark self-start">
          新增記憶
        </h1>
        <TextArea
          disabled={uploading}
          placeholder="輸入要記憶的文字內容直接建立檔案"
          style={{ resize: "none" }}
          value={text}
          onChange={(e) => setText(e.target.value)}
          maxLength={60000}
          rows={10}
          showCount
          className="mb-2"
        />
        {blobs.length > 0 ? (
          <div className="flex flex-col gap-1 max-h-[260px] overflow-auto min-h-[260px] w-full">
            {blobs.map((blob, index) => (
              <div
                key={index}
                className="flex flex-row gap-1 w-full min-h-11 items-center border border-borderLight px-4 py-2 rounded justify-between"
              >
                <div className="w-full truncate">{blob.name}</div>
                <div className="min-w-fit text-textLight">
                  {formatFileSize(blob.size)}
                  <CloseOutlined
                    disabled={uploading}
                    onClick={() => removeFile(index)}
                    className="text-textDark hover:text-red-500 cursor-pointer ml-2"
                  />
                </div>
              </div>
            ))}
            <Tooltip title="目前僅支援 .txt, .pdf, .docs, .docx, .ppt, .pptx, .csv, .xlsx, .json, .odt, .epub, .tsv 以及 .md，上傳不支援檔案將不會顯示於右側">
              <div>
                <input
                  type="file"
                  multiple
                  onChange={handleFileChange}
                  id="file-upload"
                  style={{ display: "none" }}
                  ref={fileInputRef}
                />
                <Button
                  disabled={uploading}
                  type="text"
                  className="min-h-11 w-full"
                  icon={<CloudUploadOutlined />}
                  onClick={handleClickUploadMore}
                >
                  新增更多檔案
                </Button>
              </div>
            </Tooltip>
          </div>
        ) : (
          <Tooltip title="目前僅支援 .txt, .pdf, .docs, .docx, .ppt, .pptx, .csv, .xlsx, .json, .odt, .epub, .tsv 以及 .md，上傳不支援檔案將不會顯示於右側">
            <Dragger
              disabled={uploading}
              {...props}
              className="min-h-[100px] w-full rounded-lg border-[2px] border-dashed border-gray-300 hover:border-primary transition-colors duration-300 [&_.ant-upload.ant-upload-drag]:border-0 [&_.ant-upload-drag-container]:p-6"
            >
              <p className="text-textLight text-[80px]">
                <CloudUploadOutlined />
              </p>
              <p className="ant-upload-text">點擊選擇要記憶的檔案</p>
            </Dragger>
          </Tooltip>
        )}
        <div className="flex flex-row w-full gap-1">
          <Tooltip title="網址">
            <Input
              disabled={uploading}
              placeholder="輸入要記憶的網址"
              value={url}
              onChange={(e) => setUrl(e.target.value)}
            />
          </Tooltip>
          <Tooltip title="深度">
            <Input
              disabled={uploading}
              placeholder="網址深度"
              value={depth}
              onChange={(e) => setDepth(e.target.value)}
              className="w-32"
            />
          </Tooltip>
          <Tooltip title="定期記憶">
            <div className='cursor-pointer flex items-center hover:bg-gray-100 p-1 rounded active:bg-gray-200'>
              <img src='/images/reload-icon.png' alt='reload-icon' />
            </div>
          </Tooltip>
        </div>
        <div className="flex flex-row gap-2 self-end mt-8">
          <Button disabled={uploading} onClick={reset}>
            清除
          </Button>
          <Button
            type="primary"
            onClick={handleMemorize}
            loading={uploading}
            disabled={
              text.length === 0 &&
              blobs.length === 0 &&
              (url.length === 0 || !depth)
            }
          >
            記憶
          </Button>
        </div>
      </div>
      <div className="flex flex-col w-1/2 min-h-full h-fit items-center justify-start p-4 gap-4 overflow-auto pb-8">
        <h1 className="text-lg font-bold text-textDark self-start">
          目前記憶
          <ReloadOutlined
            className="hover:text-primary ml-2"
            style={{ width: "12px", height: "12px" }}
            onClick={handleFetchMemory}
          />
        </h1>
        <div className="flex flex-col w-full">
          <div className="flex flex-row bg-bgDark h-8 items-center w-full gap-2 px-2">
            <div className="flex w-[600px] text-sm">記憶內容</div>
            <div className="flex min-w-20 text-sm">建立時間</div>
            <div className="flex min-w-12 text-sm">類型</div>
            <div className="flex min-w-4 bg-bgDark h-full ml-2" />
          </div>
          {fetching ? (
            <div className="flex justify-center items-center w-full h-72">
              <Spin
                indicator={
                  <LoadingOutlined style={{ fontSize: 48 }} spin />
                }
              />
            </div>
          ) : (
            memory.map((item, index) => (
              <div key={index} className="flex flex-row border-b border-b-borderLight h-11 items-center w-full gap-2 px-2">
                <div
                  className="flex w-[600px] cursor-pointer hover:underline truncate"
                  onClick={() =>
                    handleOpenModal(
                      item.blob.content_type,
                      item.blob.url,
                      item.id
                    )
                  }
                >
                  {formatFileName(item)}
                </div>
                <div className="flex min-w-20 text-xs">
                  {item.blob.created_at.split(" ")[0]}
                </div>
                <div className="flex min-w-12 truncate text-xs">
                  {formatFileFormat(item.blob.content_type)}
                </div>
                <CloseOutlined
                  className="text-textDark hover:text-red-500 cursor-pointer ml-2"
                  onClick={() => deleteMemory(item.id)}
                />
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default General;