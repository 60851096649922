/**
 * ChatRoom Domain Entity
 * 聊天室領域實體
 */
export default class ChatRoom {
  constructor({
    id,
    name,
    description,
    is_public,
    created_at,
    updated_at,
    owner_id,
    num_of_members
  }) {
    this.id = id;
    this.name = name;
    this.description = description;
    this.isPublic = is_public;
    this.createdAt = created_at;
    this.updatedAt = updated_at;
    this.ownerId = owner_id;
    this.numOfMembers = num_of_members;
  }

  isOwner(userId) {
    return this.ownerId === userId;
  }
} 