// Agoda Profile Entity
export class AgodaProfile {
  constructor(data) {
    this.id = data.id;
    this.chatroomId = data.chatroom_id;
    this.profileId = data.body?.id;
    this.serialNumber = data.body?.serial_number;
  }
}

// Agoda User Entity
export class AgodaUser {
  constructor(data) {
    this.userId = data.agoda_user_id;
    this.username = data.agoda_username;
    this.email = data.agoda_email;
    this.profileId = data.profile_id;
  }
}

// Agoda Source Entity
export class AgodaSource {
  constructor(data) {
    this.id = data.id;
    this.sourceId = data.agoda_source_id;
    this.sourceName = data.agoda_source_name;
    this.userId = data.agoda_user_id;
    this.name = data.agoda_conversation_guest_name;
  }
}

// Agoda Booking Entity
export class AgodaBooking {
  constructor(data) {
    this.id = data.id;
    this.bookingId = data.agoda_booking_id;
    this.status = data.agoda_booking_status;
    this.checkInDate = data.agoda_check_in_date;
    this.checkOutDate = data.agoda_check_out_date;
    this.bookingDate = data.agoda_booking_date;
    this.conversationId = data.agoda_conversation_id;
  }
}

// Agoda Message Entity
export class AgodaMessage {
  constructor(data) {
    this.id = data.id;
    this.owner = data.agoda_message_owner;
    this.content = data.agoda_content;
    this.image = data.agoda_image;
    this.sentDate = data.agoda_sent_date;
    this.sentTime = data.agoda_sent_time;
    this.sentDateTime = data.agoda_sent_datetime;
    this.conversationId = data.agoda_conversation_id;
  }
} 