import ChatRoomRepository from '../../infrastructure/repositories/ChatRoomRepository';
import GetSingleRoomUseCase from '../../application/useCases/GetSingleRoomUseCase';

/**
 * ChatRoom Facade
 * 聊天室外觀模式實現
 * 作為 UI 層和 Use Case 層之間的中介者
 */
export default class ChatRoomFacade {
  constructor(token) {
    this.chatRoomRepository = new ChatRoomRepository(token);
    this.getSingleRoomUseCase = new GetSingleRoomUseCase(this.chatRoomRepository);
  }

  /**
   * Get chat room by ID
   * 根據 ID 獲取聊天室
   * @param {string} roomId 
   * @returns {Promise<{success: boolean, data: ChatRoom | null, error: string | null}>}
   */
  async getChatRoom(roomId) {
    try {
      const room = await this.getSingleRoomUseCase.execute(roomId);
      return {
        success: true,
        data: room,
        error: null
      };
    } catch (error) {
      return {
        success: false,
        data: null,
        error: error.message
      };
    }
  }
} 