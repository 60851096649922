import React, { useState, useEffect, useRef } from "react";
import { message, Modal } from "antd";
import axios from "axios";
import { useAuth } from "../../../../contexts/AuthProvider";
import { useDispatch, useSelector } from "react-redux";
import { fetchTemplate } from "../../../../service/memoryService";
import { Advanced } from '../../../../app/chat/components/memory/components/Advanced';
import { General } from '../../../../app/chat/components/memory/components/General';
import { Qa } from '../../../../app/chat/components/memory/components/Qa';
import { SearchResult } from '../../../../app/chat/components/memory/components/SearchResult';
import SearchBar from '../../../../components/SearchBar';

import TemplateEditor from "./components/TemplateEditor";
import Record from "./components/Record";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

const Memory = ({ room }) => {
  const dispatch = useDispatch();
  const template = useSelector((state) => state.memory.template);
  const { token } = useAuth();
  const [text, setText] = useState("");
  const [url, setUrl] = useState("");
  const [blobs, setBlobs] = useState([]);
  const [uploading, setUploading] = useState(false);
  const fileInputRef = useRef(null);
  const [memory, setMemory] = useState([]);
  const [depth, setDepth] = useState(1);
  const [fetching, setFetching] = useState(false);
  const [selectedButton, setSelectedButton] = useState("general");
  const [previousButton, setPreviousButton] = useState("general");
  const [showTemplateEditor, setShowTemplateEditor] = useState(false);
  const [showRecord, setShowRecord] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [selectedTemplateIndex, setSelectedTemplateIndex] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchRange, setSearchRange] = useState("全部搜尋");
  const [openModal, setOpenModal] = useState(false);
  const [modalUrl, setModalUrl] = useState("");
  const [currentRecordId, setCurrentRecordId] = useState(null);
  // 搜尋選項
  const memorySearchOptions = [
    { value: '全部搜尋', label: '全部搜尋' },
    { value: '一般記憶', label: '一般記憶' },
    { value: '進階記憶', label: '進階記憶' },
    { value: '問答記憶', label: '問答記憶' },
  ];

  // Fetch data
  useEffect(() => {
    reset();
    setShowTemplateEditor(false);
    setShowRecord(false);
    handleFetchTemplate();
    handleFetchMemory();
  }, [room, selectedButton]);

  const reset = () => {
    setText("");
    setUrl("");
    setBlobs([]);
    setDepth(1);
  };

  const handleFetchTemplate = async () => {
    setFetching(true);
    try {
      await dispatch(fetchTemplate({ room_id: room.id }));
    } finally {
      setFetching(false);
    }
  };

  const handleSearch = (text, range) => {
    setSearchQuery(text);
    setSearchRange(range);
    
    if (text) {
      if (selectedButton !== "search") {
        setPreviousButton(selectedButton);
        setSelectedButton("search");
      }
    } else {
      setSelectedButton(previousButton);
    }
  };

  const handleClickUploadMore = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (e) => {
    setBlobs([...blobs, ...Array.from(e.target.files)]);
  };

  const removeFile = (index) => {
    setBlobs(blobs.filter((_, i) => i !== index));
  };

  const props = {
    name: "file",
    multiple: true,
    onChange: (e) => {
      setBlobs([...blobs, ...e.fileList.map((file) => file.originFileObj)]);
    },
    onDrop: (e) => {
      setBlobs([...blobs, ...Array.from(e.dataTransfer.files)]);
    },
  };

  const handleFetchMemory = async () => {
    setFetching(true); // Assuming setFetching updates a state to show loading status
    try {
      const response = await axios.get(
        `${SERVER_URL}/private/chatrooms/vector_store/${room.id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // Use Promise.all to handle multiple fetches concurrently
      const fetchTextFiles = response.data.map(async (item) => {
        if (item.blob && item.blob.content_type.includes("text/plain")) {
          try {
            const textResponse = await axios.get(item.blob.url);
            const content =
              typeof textResponse.data === "string"
                ? textResponse.data
                : JSON.stringify(textResponse.data);
            const filename = content.substring(0, 50);
            return {
              ...item,
              blob: { ...item.blob, content: content, filename: filename },
            }; // storing the fetched data in 'content'
          } catch (error) {
            // message.error(JSON.stringify(error));
            // console.log(error);
            return item; // return the item unchanged if there was an error
          }
        }
        return item;
      });

      const updatedData = await Promise.all(fetchTextFiles);
      setMemory(updatedData); // Assuming setMemory updates the state with the new data
    } catch (error) {
      message.error(JSON.stringify(error));
    }
    setFetching(false); // Ensure fetching is set to false after operations complete
  };

  const handlePostText = async () => {
    try {
      await axios.post(
        SERVER_URL + "/private/chatrooms/vector_store/text/" + room.id,
        {
          text: text,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } catch (error) {
      message.error(JSON.stringify(error));
    }
  };

  const handlePostUrl = async () => {
    try {
      await axios.post(
        SERVER_URL + "/private/chatrooms/vector_store/url/" + room.id,
        {
          url: url,
          depth: depth,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } catch (error) {
      message.error(JSON.stringify(error));
    }
  };

  const handlePostFiles = async () => {
    try {
      const formData = new FormData();
      blobs.forEach((blob) => {
        formData.append("files", blob);
      });

      await axios.post(
        SERVER_URL + "/private/chatrooms/vector_store/file/" + room.id,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } catch (error) {
      message.error(JSON.stringify(error));
    }
  };

  const handleMemorize = async () => {
    setUploading(true);
    if (text.length > 0) {
      await handlePostText();
    }
    if (url.length > 0) {
      await handlePostUrl();
    }
    if (blobs.length > 0) {
      await handlePostFiles();
    }
    reset();
    handleFetchMemory();
    setUploading(false);
    message.open({
      type: "success",
      content: "記憶成功送出！若檔案類型支援，稍後重整即可在右側查看",
      duration: 5,
    });
  };

  const handleOpenModal = async (type, url, id) => {
    setCurrentRecordId(id);
    if (type === "url") {
      window.open(url, "_blank");
      return;
    }
    if (type.includes("text/plain")) {
      try {
        const response = await axios.get(url);
        const content = typeof response.data === 'string' 
          ? response.data 
          : JSON.stringify(response.data, null, 2);
        setModalUrl(content);
        setOpenModal(true);
      } catch (error) {
        message.error('無法讀取文字檔案內容');
        console.error('Error fetching text file:', error);
      }
      return;
    }
    window.open(url, "_blank");
    return;
  };

  const deleteMemory = async (recordId) => {
    setFetching(true);
    try {
      await axios.delete(
        SERVER_URL +
        "/private/chatrooms/vector_store/" +
        room.id +
        "?record_id=" +
        recordId,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      message.error('刪除成功');
    } catch (error) {
      message.error(JSON.stringify(error));
    }
    handleFetchMemory();
    handleFetchTemplate();
    setFetching(false);
  };

  const formatFileName = (item) => {
    if (item.blob.content_type === "webpage") {
      return item.blob.url;
    }
    if (item.blob.filename) {
      return item.blob.filename;
    }
    return item.blob.id;
  };

  const formatFileFormat = (type) => {
    if (type === "webpage") return "網頁存擋";
    if (type.includes("text/plain")) return "文字檔";
    return "檔案";
  };

  const formatFileSize = (size) => {
    if (size < 1024) return size + " bytes";
    else if (size >= 1024 && size < 1048576)
      return (size / 1024).toFixed(1) + " KB";
    else return (size / 1048576).toFixed(1) + " MB";
  };

  const handleButtonClick = (buttonType) => {
    setPreviousButton(selectedButton);
    setSelectedButton(buttonType);
  };
    
  // 渲染 MemoryNavBar
  const renderMemoryNavBar = () => {
    return (
      <div className="flex justify-between items-center rounded mx-3 my-2">
        <div className="flex bg-gray-100">
          <button
            onClick={() => handleButtonClick("general")}
            className={`px-2 py-1 flex-1 rounded ${selectedButton === "general" ? "bg-[#ADDEAC]" : "bg-gray-100"}`}
          >
            一般記憶
          </button>
          <button
            onClick={() => handleButtonClick("advanced")}
            className={`px-2 py-1 flex-1 rounded ${selectedButton === "advanced" ? "bg-[#ADDEAC]" : "bg-gray-100"}`}
          >
            進階記憶
          </button>
          <button
            onClick={() => handleButtonClick("qa")}
            className={`px-2 py-1 flex-1 rounded ${selectedButton === "qa" ? "bg-[#ADDEAC]" : "bg-gray-100"}`}
          >
            問答記憶
          </button>
        </div>
        <div className="w-64 mr-2">
          <SearchBar
            options={memorySearchOptions}
            onSearch={handleSearch}
            placeholder="依照範圍搜尋"
            defaultSearchRange={searchRange}
            value={searchQuery}
          />
        </div>
      </div>
    );
  };

  // 渲染主要內容
  const renderContent = () => {
    const commonProps = {
      handleButtonClick,
      selectedButton,
      onSearch: handleSearch,
      searchQuery,
      searchRange,
      openModal,
      modalUrl,
      currentRecordId,
      handleOpenModal,
      handleFetchMemory,
      handleFetchTemplate,
    };

    if (selectedButton === "general") {
      return (
        <div className="flex flex-col w-full h-full">
          {renderMemoryNavBar()}
          <div className="flex flex-row w-full h-full border-t">
            <General
              {...commonProps}
              uploading={uploading}
              text={text}
              setText={setText}
              blobs={blobs}
              formatFileSize={formatFileSize}
              removeFile={removeFile}
              handleFileChange={handleFileChange}
              fileInputRef={fileInputRef}
              handleClickUploadMore={handleClickUploadMore}
              props={props}
              url={url}
              setUrl={setUrl}
              depth={depth}
              setDepth={setDepth}
              reset={reset}
              handleMemorize={handleMemorize}
              fetching={fetching}
              memory={memory}
              formatFileName={formatFileName}
              formatFileFormat={formatFileFormat}
              deleteMemory={deleteMemory}
              handleFetchMemory={handleFetchMemory}
            />
          </div>
        </div>
      );
    }

    if (selectedButton === "advanced") {
      return (
        <div className="flex flex-col w-full h-full">
          {renderMemoryNavBar()}
          <div className="flex flex-row w-full h-full border-t">
            {showTemplateEditor ? (
              <TemplateEditor
                {...commonProps}
                setShowTemplateEditor={setShowTemplateEditor}
                templateData={selectedTemplate}
                templateIndex={selectedTemplateIndex}
                setSelectedTemplate={setSelectedTemplate}
                room={room}
              />
            ) : showRecord ? (
              <Record
                {...commonProps}
                fetching={fetching}
                template={template}
                setSelectedTemplate={setSelectedTemplate}
                setSelectedTemplateIndex={setSelectedTemplateIndex}
                setShowTemplateEditor={setShowTemplateEditor}
                handleFetchTemplate={handleFetchTemplate}
                setShowRecord={setShowRecord}
                memory={memory}
                deleteMemory={deleteMemory}
                formatFileName={formatFileName}
                formatFileFormat={formatFileFormat}
                fetchTemplate={fetchTemplate}
                setFetching={setFetching}
                room={room}
                token={token}
                dispatch={dispatch}
                templateData={selectedTemplate}
              />
            ) : (
              <Advanced
                {...commonProps}
                fetching={fetching}
                template={template}
                setSelectedTemplate={setSelectedTemplate}
                setSelectedTemplateIndex={setSelectedTemplateIndex}
                setShowTemplateEditor={setShowTemplateEditor}
                handleFetchMemory={handleFetchMemory}
                setShowRecord={setShowRecord}
                memory={memory}
                deleteMemory={deleteMemory}
                formatFileName={formatFileName}
                formatFileFormat={formatFileFormat}
                fetchTemplate={fetchTemplate}
                setFetching={setFetching}
                room={room}
                token={token}
                dispatch={dispatch}
                handleFetchTemplate={handleFetchTemplate}
              />
            )}
          </div>
        </div>
      );
    }

    if (selectedButton === "qa") {
      return (
        <div className="flex flex-col w-full h-full">
          {renderMemoryNavBar()}
          <div className="flex flex-row w-full h-full border-t">
            <Qa
              {...commonProps}
              chatroomId={room.id}
            />
          </div>
        </div>
      );
    }

    if (selectedButton === "search") {
      return (
        <div className="flex flex-col w-full h-full">
          {renderMemoryNavBar()}
          <div className="flex flex-row w-full h-full border-t">
            <SearchResult
              {...commonProps}
              chatroomId={room.id}
            />
          </div>
        </div>
      );
    }

    return null;
  };

  return (
    <>
      <div
        className="flex flex-col h-full overflow-auto"
        style={{ width: "calc(100vw - 620px)", height: "100%" }}
      >
        {renderContent()}
      </div>
      <Modal
        title="文字檔案內容"
        open={openModal}
        onCancel={() => setOpenModal(false)}
        footer={null}
        width={800}
      >
        <div className="max-h-[600px] overflow-auto">
          <pre className="whitespace-pre-wrap">
            {typeof modalUrl === 'string' ? modalUrl : JSON.stringify(modalUrl, null, 2)}
          </pre>
        </div>
      </Modal>
    </>
  );
};

export default Memory;
