import { useState, useRef } from 'react';
import axios from 'axios';
import { Avatar, Spin, Tooltip, message } from "antd";
import { UserOutlined, LoadingOutlined } from '@ant-design/icons';

import { useAuth } from '../../../contexts/AuthProvider';

const MgntAvatar = ({ photoUrl, uploadUrl, title, name, refresh }) => {
    const { token } = useAuth();
    const fileInputRef = useRef(null);
    const [loading, setLoading] = useState(false);

    const handlePhotoChange = async (event) => {
        const file = event.target.files[0];
        if (file) {
            // if file size is over 2 MB return
            if (file.size > 2097152) {
                message.error('檔案大小超出 2MB，請重新選擇');
                return;
            }

            // if file name is too long return
            if (file.name.length > 100) {
                message.error('圖片名稱過長，請重新命名');
                return;
            }

            const formData = new FormData();
            formData.append('file', file);
            setLoading(true);
            try {
                await axios.patch(uploadUrl, formData, {
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': `Bearer ${token}`
                    },
                });
                message.success('圖片上傳成功');
                refresh();
                // window.location.reload();
            } catch (error) {
                message.error('文件上傳失敗', error.response.data.detail);
            } finally {
                setLoading(false);
            }
        }
    };

    const handleUpload = () => {
        fileInputRef.current.click();
    };


    return (
        <div className='flex flex-col w-full p-4'>
            {/* <Spin spinning={loading} indicator={<LoadingOutlined spin />}> */}
            <h3 className='font-semibold text-[17px]'>{title}</h3>
            <div className='flex w-full justify-between items-center'>
                <div className='flex w-full  justify-start items-center'>
                    <Spin spinning={loading} indicator={<LoadingOutlined spin />} >
                        <Avatar size={80} icon={<UserOutlined />}
                            // src={photoUrl ? photoUrl : undefined}
                            src={photoUrl ? `${photoUrl}?v=${Date.now()}` : undefined}
                        />
                    </Spin>
                    <p className='text-[18px] font-semibold ml-3 break-words text-wrap'>{name}</p>
                </div>
                <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: 'none' }}
                    onChange={handlePhotoChange}
                />
                <Tooltip title='最大 2MB 之 JPG 或 PNG'>
                    <button className='text-[14px] bg-primaryMedium p-2 rounded-[3px] w-[90px] h-[31px] flex items-center justify-center'
                        onClick={handleUpload}>
                        上傳檔案</button>
                </Tooltip>
            </div>
            {/* </Spin> */}
        </div>
    )
}

export default MgntAvatar;