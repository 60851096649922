import React, { useState, useEffect } from 'react';
import { Spin, List, Empty, Tag } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { SearchFacade } from '../../../../../application/facades/SearchFacade';

// SearchResult component
export const SearchResult = ({
  chatroomId,
  searchQuery,
  searchRange
}) => {
  const [fetching, setFetching] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const searchFacade = new SearchFacade();

  // Search function
  const handleSearch = async () => {
    if (!searchQuery) return;
    
    setFetching(true);
    try {
      const results = await searchFacade.search(chatroomId, searchQuery, searchRange);
      setSearchResults(results);
    } catch (error) {
      console.error('搜尋時發生錯誤:', error);
    } finally {
      setFetching(false);
    }
  };

  // 當搜尋條件改變時執行搜尋
  useEffect(() => {
    handleSearch();
  }, [searchQuery, searchRange]);

  // 格式化檔案名稱
  const formatFileName = (item) => {
    return item.content;
  };

  // 格式化檔案類型
  // const formatFileFormat = (type) => {
  //   if (type === "webpage") return "網頁存檔";
  //   if (type?.includes("text/plain")) return "文字檔";
  //   return "檔案";
  // };

  return (
    <div className="flex flex-col w-full h-full justify-start p-3 gap-1">
      {/* 表頭 */}
      <div className="flex flex-row justify-start bg-bgDark h-8 items-center w-full gap-3 px-2">
        <div className="flex justify-start text-sm w-1/3">內容</div>
        <div className="flex justify-start text-sm w-1/3">類型</div>
        <div className="flex justify-start text-sm w-1/3">建立時間</div>
      </div>

      {/* 搜尋結果列表 */}
      {fetching ? (
        <div className="flex justify-center items-center w-full h-72">
          <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
        </div>
      ) : (
        <List
          dataSource={searchResults}
          locale={{
            emptyText: <Empty description="無搜尋結果" />
          }}
          renderItem={(item) => (
            <List.Item className="flex flex-row w-full items-center px-2 gap-3">
              <div className="w-1/3 truncate">
                {formatFileName(item)}
              </div>
              <div className="w-1/3">
                <Tag color={
                  item.type === '一般記憶' ? 'blue' :
                  item.type === '進階記憶' ? 'green' :
                  'purple'
                }>
                  {item.type}
                </Tag>
              </div>
              <div className="w-1/3 text-sm text-gray-500">
                {item.createdAt?.split(' ')[0] || '無日期'}
              </div>
            </List.Item>
          )}
        />
      )}
    </div>
  );
};

export default SearchResult; 