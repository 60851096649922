export const AgodaUseCases = {
  // Get complete Agoda chat flow
  getAgodaChatFlow: async (repository, chatroomId, token) => {
    try {
      // Step 1: Get profile
      const profile = await repository.getProfile(chatroomId, token);
      if (!profile.profileId) {
        throw new Error('無法獲取 Profile ID');
      }

      // Step 2: Get user info
      const users = await repository.getUserInfo(profile.profileId);
      if (!users.length) {
        throw new Error('無法獲取使用者資訊');
      }
      const user = users[0];

      // Step 3: Get source info
      const sources = await repository.getSourceInfo(user.userId);
      if (!sources.length) {
        throw new Error('無法獲取房源資訊');
      }

      // Step 4: Get conversation IDs for each source
      const conversationPromises = sources.map(source => 
        repository.getConversationId(source.id)
      );
      const conversationsArrays = await Promise.all(conversationPromises);
      
      // Filter out empty arrays and flatten the array
      const validConversations = conversationsArrays.flat().filter(conversation => conversation && conversation.id);
      
      // Step 5: Get booking info and messages for each conversation
      const chatData = await Promise.all(
        validConversations.map(async (conversation) => {
          const [bookings, messages] = await Promise.all([
            repository.getBookingInfo(conversation.id),
            repository.getConversationMessages(conversation.id)
          ]);
          
          return {
            conversationId: conversation.id,
            agodaConversationId: conversation.agoda_conversation_id,
            agodaSourceId: conversation.agoda_source_id,
            agodaGuestName: conversation.agoda_conversation_guest_name,
            bookings,
            messages
          };
        })
      );

      return {
        profile,
        user,
        sources,
        chatData
      };
    } catch (error) {
      // console.error('UseCase Error in getAgodaChatFlow:', error);
      throw error;
    }
  },

  // Get messages for specific conversation
  getConversationData: async (repository, conversationId) => {
    try {
      console.log('conversationId', conversationId);
      const [bookings, messages] = await Promise.all([
        repository.getBookingInfo(conversationId),
        repository.getConversationMessages(conversationId)
      ]);

      // Reverse the messages array to display in descending order
      const reversedMessages = messages ? [...messages].reverse() : [];

      return {
        bookings,
        messages: reversedMessages
      };
    } catch (error) {
      console.error('UseCase Error in getConversationData:', error);
      throw error;
    }
  }
}; 