import axios from 'axios';
import { getAuthConfig, getOfficialAuthConfig } from '../app/ams/infrastructure/api/AuthApi';

const Official = process.env.REACT_APP_E_OFFICIAL_SITE_MODULE === 'true';
const callApi = axios.create({
  timeout: 200000,
  retry: 3,
  retryDelay: 1000,
});

callApi.interceptors.request.use(async (config) => {
  try {
    let authConfig;
    if(Official) {
      authConfig = await getOfficialAuthConfig();
    } else {
      authConfig = await getAuthConfig(true);
    }

    config.baseURL = authConfig.serverUrl;
    config.headers.Authorization = `Bearer ${authConfig.accessToken}`;
    config.maxRedirects = 5;
    config.followRedirects = true;
    config.validateStatus = function (status) {
      return status === 200;
    };
    return config;
  } catch (error) {
    console.error('API 配置錯誤:', error);
    return Promise.reject(new Error('API 配置失敗: ' + error.message));
  }
}, (error) => {
  console.error('API 請求攔截器錯誤:', error);
  return Promise.reject(new Error('API 請求準備失敗: ' + error.message));
});

callApi.interceptors.response.use(
  (response) => {
    if (!response.data) {
      return Promise.reject(new Error('響應數據為空'));
    }
    return response;
  },
  async (error) => {
    const config = error.config;
    
    // 如果是網絡錯誤或超時
    if (!error.response) {
      console.error('網絡錯誤:', error.message);
      if (config && config.retry && (!config.retryCount || config.retryCount < config.retry)) {
        config.retryCount = (config.retryCount || 0) + 1;
        return new Promise(resolve => {
          setTimeout(() => resolve(callApi(config)), config.retryDelay || 1000);
        });
      }
      return Promise.reject(new Error('網絡連接失敗，請檢查網絡狀態'));
    }
    return Promise.reject(error);
  }
);

export default callApi;
