import { ISearchRepository } from '../../domain/interfaces/ISearchRepository';
import { request } from '../../utils';
import { SearchApi } from '../../infrastructure/api/SearchApi';

export class SearchRepository extends ISearchRepository {
  /**
   * @inheritdoc
   */
  async searchClients(chatroomId, searchText) {
    try {
      console.log('SearchRepository searchText:', searchText); // 調試日誌
      const response = await SearchApi.searchClients(chatroomId, searchText);
      return response;
    } catch (error) {
      console.error('SearchRepository.searchClients Error:', error);
      throw error;
    }
  }

  async searchGeneralMemories(chatroomId, keyword, limit) {
    try {
      const response = await request.get(
        `/private/chatrooms/vector_store/${chatroomId}/search`,
        {
          params: {
            keyword,
            limit,
            templated: false
          }
        }
      );
      return response || [];
    } catch (error) {
      console.error('SearchRepository.searchGeneralMemories Error:', error);
      throw error;
    }
  }

  async searchAdvancedMemories(chatroomId, keyword, limit) {
    try {
      const response = await request.get(
        `/private/chatrooms/vector_store/${chatroomId}/search`,
        {
          params: {
            keyword,
            limit,
            templated: true
          }
        }
      );
      return response || [];
    } catch (error) {
      console.error('SearchRepository.searchAdvancedMemories Error:', error);
      throw error;
    }
  }

  async searchQAMemories(chatroomId, keyword, limit) {
    try {
      const response = await request.get(
        `/private/chatrooms/vector_store/qa/${chatroomId}/search`,
        {
          params: {
            keyword,
            limit
          }
        }
      );
      return response || [];
    } catch (error) {
      console.error('SearchRepository.searchQAMemories Error:', error);
      throw error;
    }
  }
} 