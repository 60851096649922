import * as settingApi from '../api/SettingApi';

/**
 * Update chatroom chat settings
 * @param {string} chatroomId - The ID of the chatroom
 * @param {Object} settings - The settings to update
 * @returns {Promise} Repository response
 */
export const updateChatroomChatSettings = async (chatroomId, settings) => {
  try {
    return await settingApi.updateChatroomChatSettings(chatroomId, settings);
  } catch (error) {
    throw error;
  }
};
