import * as modelApi from '../api/ModelApi';

/**
 * Get available chat models
 * @returns {Promise<Object>} Repository response with model information
 */
export const getChatModels = async () => {
  try {
    return await modelApi.getChatModels();
  } catch (error) {
    throw error;
  }
};

/**
 * Get chatroom model settings
 * @param {string} chatroomId - The ID of the chatroom
 * @returns {Promise<Object>} Repository response with chatroom model settings
 */
export const getChatroomModelSettings = async (chatroomId) => {
  try {
    return await modelApi.getChatroomModelSettings(chatroomId);
  } catch (error) {
    throw error;
  }
};

/**
 * Update chatroom model settings
 * @param {string} chatroomId - The ID of the chatroom
 * @param {Object} modelSettings - The model settings to update
 * @returns {Promise<Object>} Repository response with updated chatroom model settings
 */
export const updateChatroomModelSettings = async (chatroomId, modelSettings) => {
  try {
    return await modelApi.updateChatroomModelSettings(chatroomId, modelSettings);
  } catch (error) {
    throw error;
  }
};

/**
 * Get available text to image models
 * @returns {Promise<Object>} Repository response with model information
 */
export const getTextToImageModels = async () => {
  try {
    return await modelApi.getTextToImageModels();
  } catch (error) {
    throw error;
  }
}; 