import React, { useState, useEffect } from "react";
import mqtt from "mqtt";
import { useAuth } from "../../../../contexts/AuthProvider";
import { useBound } from "../../../../contexts/BoundContext";
import BoundList from "./BoundList";
import BoundChatRoom from "./BoundChatRoom";
import BoundSetting from "./BoundSetting";
import { LineChannelFacade } from "../../../../application/facades/LineChannelFacade";
import { MQTT_URL, MQTT_PORT } from "../../../../constants/constants";
import { useAgoda } from "../../../../hooks/useAgoda";

const BoundInterface = ({ room, switchTo }) => {
  const { userId, token } = useAuth();
  const { setListLoading } = useBound();

  // 使用新的 useAgoda hook
  const {
    error: agodaError,
    initializeAgodaChat,
    processAllConversations,
    loading: agodaLoading
  } = useAgoda();

  

  const [clients, setClients] = useState([]);
  const [clientsAgoda, setClientsAgoda] = useState([]);
  const [activeId, setActiveId] = useState('');
  const [activeClient, setActiveClient] = useState(null);
  const [activeTab, setActiveTab] = useState(0);
  const [newMessageClientList, setNewMessageClientList] = useState([]);
  const [mqttConnection, setMqttConnection] = useState(false);
  
  const roomId = room.id;
  const tabList = [
    {
      key: 0,
      label: "Agoda",
    },
    {
      key: 1,
      label: "Line",
    },
  ];
  // 重置效果
  useEffect(() => {
    setMqttConnection(false);
    setActiveId("");
    setActiveClient(null);
    setNewMessageClientList([]);
  }, [room]);

  // 處理標籤切換
  useEffect(() => {
    setActiveId("");
    setActiveClient(null);
    setClientsAgoda([]);

    if (activeTab === 0) {
      // 初始化 Agoda 聊天室
      const initAgoda = async () => {
        try {
          const data = await initializeAgodaChat(roomId);
          console.log('初始化 Agoda 數據:', data);

          if (data?.sources && data?.chatData) {
            // 創建一個映射，用於快速查找 source 信息
            const sourceMap = new Map();
            data.sources.forEach(source => {
              sourceMap.set(source.id, source);
            });
            
            // 將 chatData 與 sources 進行比對
            const agodaClients = data.chatData.map(chat => {
              // 查找匹配的 source
              const matchedSource = sourceMap.get(chat.agodaSourceId);
              
              // 獲取最後一條消息的內容
              // let lastMessageContent = "";
              let lastMessageTime = chat.messages[chat.messages.length - 1]?.sentDateTime || new Date().toISOString();
              
              if (chat.messages && chat.messages.length > 0) {
                // 按時間排序消息
                const sortedMessages = [...chat.messages].sort((a, b) => {
                  const timeA = a.timestamp || a.send_date || a.created_at;
                  const timeB = b.timestamp || b.send_date || b.created_at;
                  return new Date(timeB) - new Date(timeA);
                });
                
                // 獲取最後一條消息的內容
                // lastMessageContent = sortedMessages[0]?.content || sortedMessages[0]?.body || "";
                lastMessageTime = sortedMessages[0]?.timestamp || sortedMessages[0]?.send_date || sortedMessages[0]?.created_at || lastMessageTime;
              }
              
              // 判斷消息是否來自用戶
              // 如果 owner 為 Agoda 或 User，則是我方發送的訊息，應該顯示在右邊 (message)
              // 如果 owner 為 Guest 或 sender_role 包含 guest，則是客戶發送的訊息，應該顯示在左邊 (response.message)
              const isAgodaMessage = chat.messages[0]?.owner === 'Agoda' || chat.messages[0]?.owner === 'User';
              
              // 處理所有消息，確保每條消息都有正確的格式
              const processedMessages = chat.messages.map(msg => {
                const msgIsAgodaMessage = msg.owner === 'Agoda' || msg.owner === 'User';
                
                return {
                  id: msg.id || `msg-${Math.random().toString(16).slice(2)}`,
                  channel_type: "agoda",
                  channel_id: "agoda-channel",
                  client_id: chat.conversationId,
                  created_at: msg.timestamp || msg.send_date || msg.created_at || msg.sentDateTime || new Date().toISOString(),
                  // 如果是 Agoda 或 User 消息，則設置 message，否則設置為空
                  message: msgIsAgodaMessage ? (msg.content || msg.body || "") : "",
                  user_id: chat.conversationId,
                  // 設置發送者名稱
                  user_nickname: msgIsAgodaMessage ? "Agoda" : (msg.sender || "Guest"),
                  user_image_url: msg.sender_photo_url || null,
                  blobs: [],
                  
                  response: {
                    id: `resp-${Math.random().toString(16).slice(2)}`,
                    channel_type: "agoda",
                    channel_id: "agoda-channel",
                    created_at: msg.timestamp || msg.send_date || msg.created_at || msg.sentDateTime || new Date().toISOString(),
                    // 如果不是 Agoda 或 User 消息，則設置 succeed 為 true
                    succeed: !msgIsAgodaMessage,
                    // 如果不是 Agoda 或 User 消息，則設置 response.message，否則設置為空
                    message: !msgIsAgodaMessage ? (msg.content || msg.body || "") : "",
                    blobs: [],
                    tokens: 0,
                    costs: 0
                  }
                };
              });
              
              return {
                userId: chat.conversationId,
                messageId: chat.conversationId,
                roomId: roomId,
                displayName: matchedSource ? matchedSource.sourceName : "Unknown Guest",
                name: matchedSource ? matchedSource.sourceName : "Unknown Guest",
                agoda_conversation_guest_name: chat.agodaGuestName,
                channel: "agoda",
                last_two_messages: [
                  {
                    created_at: chat.messages[chat.messages.length - 1]?.sentDateTime || lastMessageTime, 
                    message: chat.messages[chat.messages.length - 1]?.content || chat.messages[chat.messages.length - 1]?.body || ""
                  }
                ],
                conversationData: {
                  ...chat,
                  processedMessages: processedMessages // 添加處理後的消息數據
                },
                pictureUrl: null,
                unreadCount: 0,
                id: chat.messages[0]?.id || `msg-${Math.random().toString(16).slice(2)}`,
                channel_type: "agoda",
                channel_id: "agoda-channel",
                client_id: chat.conversationId,
                created_at: chat.messages[0]?.timestamp || chat.messages[0]?.send_date || chat.messages[0]?.created_at || new Date().toISOString(),
                message: (chat.messages[0]?.content || chat.messages[0]?.body || "") ,
                user_id: chat.conversationId,
                user_nickname: isAgodaMessage ? "Agoda" : (chat.messages[0]?.sender || "Guest"),
                user_image_url: chat.messages[0]?.sender_photo_url || null,
                blobs: [],
                response: {
                  id: `resp-${Math.random().toString(16).slice(2)}`,
                  channel_type: "agoda",
                  channel_id: "agoda-channel",
                  created_at: chat.messages[0]?.timestamp || chat.messages[0]?.send_date || chat.messages[0]?.created_at || new Date().toISOString(),
                  succeed: !isAgodaMessage,
                  message: (chat.messages[0]?.content || chat.messages[0]?.body || "") ,
                  blobs: [],
                  tokens: 0,
                  costs: 0
                }
              };
            });
            
            console.log('處理後的 Agoda 客戶列表:', agodaClients);
            setClientsAgoda(agodaClients);
          } else if (data?.sources) {
            // 如果沒有 chatData，則使用原來的邏輯
            const conversationsData = await processAllConversations(data.sources);
            
            // 將房源資訊轉換為客戶列表格式
            const agodaClients = data.sources.map((source, index) => {
              // 確保 conversationsData[index] 有值
              const conversation = conversationsData[index] || {};
              
              return {
                userId: source.sourceId,
                name: source.sourceName,
                roomId: roomId,
                displayName: source.sourceName,
                agoda_conversation_guest_name: source.name,
                channel: "agoda",
                last_two_messages: [
                  {
                    created_at: new Date().toISOString(),
                    message: "No messages yet"
                  }
                ],
                conversationData: {
                  ...conversation,
                  processedMessages: [] // 添加空的處理後消息數據
                }
              };
            });
            
            setClientsAgoda(agodaClients);
          }
        } catch (error) {
          console.error('初始化 Agoda 聊天室失敗:', error);
        }
      };
      
      initAgoda();
    } else if (activeTab === 1) {
      const FetchLine = async () => {
        try {
          setListLoading(true);
          const res = await LineChannelFacade.fetchLineClients(room.id);
          setClients(res);
        } catch (error) {
          console.error('Error fetching Line channel:', error);
        } finally {
          setListLoading(false);
        }
      }
      FetchLine();
    }
  }, [activeTab, roomId, initializeAgodaChat, processAllConversations]);

  // 處理活動客戶變更
  useEffect(() => {
    const sourceClients =
      activeTab === 0
        ? clientsAgoda
        : activeTab === 1
          ? clients
          : '';
    setActiveClient(sourceClients.find((client) => client.userId === activeId));
    setNewMessageClientList((prevList) =>
      prevList.filter((clientId) => clientId !== activeId)
    );
  }, [activeId, activeTab, clients, clientsAgoda]);

  // MQTT 連接處理
  useEffect(() => {
    // Establish connection with the MQTT broker
    const mqttClient = mqtt.connect(MQTT_URL, {
      clientId: 'mqttx_' + Math.random().toString(16).substring(2, 10),
      port: MQTT_PORT,
      path: '/',
      connectTimeout: 10000,
      keepAlive: 30000,
      autoReconnect: true,
      reconnectPeriod: 1000,
      cleanStart: false,
    });

    mqttClient.on('error', (error) => {
      // console.error('Connection error:', error);
      setMqttConnection(false);
    });

    mqttClient.on('connect', () => {
      mqttClient.subscribe(`chatroom/${room.id}/line/#`, (error) => {
        if (error) {
          // console.error('Subscription error:', error);
          setMqttConnection(false);
        } else {
          // console.log('Subscription successful');
          setMqttConnection(true);
        }
      });
    });

    mqttClient.on('message', (topic, payload) => {
      const receivedClientId = topic.split('/')[3].toString();
      // console.log('INTERFACE | Message received:', receivedClientId, "content", payload.toString());
      // console.log('INTERFACE | Active clients:', clients);

      // filter new client
      // const newClient = clients.find(client => client.userId === receivedClientId);
      // console.log('the new client', newClient)

      // unfocused client got message
      if (receivedClientId !== activeId) {
        setNewMessageClientList((prevList) => [...prevList, receivedClientId]);
      }
      // new client or message
      const FetchLine = async () => {
        try {
          const res = await LineChannelFacade.fetchLineClients(room.id);
          console.log('res', res);
          setClients(res);
        } catch (error) {
          console.error('Error fetching Line channel:', error);
        }
      }
      FetchLine();
      
    });
    // Clean up the connection when the component unmounts
    return () => mqttClient.end();
  }, [clients, activeId]);

  // 變更標籤
  const onChangeTab = (key) => {
    setActiveTab(key);
  };

  // 更新客戶資料
  const updateClientData = async () => {
    if (activeTab === 1) {
      try {
        const res = await LineChannelFacade.fetchLineClients(room.id);
        const updatedActiveClient = res.find(client => client.userId === activeId);
        
        if (updatedActiveClient) {
          setActiveClient(prevClient => ({
            ...prevClient,
            notes: updatedActiveClient.notes,
            tags: updatedActiveClient.tags
          }));
          
          setClients(prevClients => 
            prevClients.map(client => 
              client.userId === activeId 
                ? { ...client, notes: updatedActiveClient.notes, tags: updatedActiveClient.tags }
                : client
            )
          );
        }
      } catch (error) {
        console.error('Error updating Line client data:', error);
      }
    }
  };

  // 如果有 Agoda 錯誤，顯示錯誤信息
  if (agodaError && activeTab === 0) {
    // console.error('Agoda Error:', agodaError);
  }

  return (
    <div className="w-full flex flex-row">
      <BoundList
        activeId={activeId}
        setActiveId={setActiveId}
        tabs={tabList}
        activeTab={activeTab}
        onChangeTab={onChangeTab}
        switchTo={switchTo}
        mqttConnection={mqttConnection}
        newMessageClientList={newMessageClientList}
        clients={activeTab === 0 ? clientsAgoda : activeTab === 1 ? clients : ''}
        setClientsAgoda={setClientsAgoda}
        setClients={setClients}
        loading={activeTab === 0 ? agodaLoading : false}
        roomId={roomId}
        clientsAgoda={clientsAgoda}
        room={room}
      />
      {activeClient ? (
        <>
          <BoundChatRoom
            userId={userId}
            token={token}
            room={room}
            client={activeClient}
            tabs={activeTab}
            activeId={activeId}
          />
          <BoundSetting
            room={room}
            token={token}
            clientId={activeClient.userId}
            tabs={activeTab}
            client={activeClient}
            updateClientData={updateClientData}
          />
        </>
      ) : (
        <div className="flex flex-row items-center justify-center w-full h-full text-2xl font-bold text-textLight">
          請選擇第三方紀錄
        </div>
      )}
    </div>
  );
};

export default BoundInterface;
