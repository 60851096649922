import { AgodaApi } from '../api/AgodaApi';
import { 
  AgodaProfile, 
  AgodaUser, 
  AgodaSource, 
  AgodaBooking, 
  AgodaMessage 
} from '../../domain/entities/agoda/AgodaEntity';

export const AgodaRepository = {
  getProfile: async (chatroomId, token) => {
    try {
      const data = await AgodaApi.getProfile(chatroomId, token);
      return new AgodaProfile(data);
    } catch (error) {
      console.error('Repository Error in getProfile:', error);
      if (error.response?.status === 500) {
        throw new Error('伺服器錯誤');
      }
      throw error;
    }
  },

  getUserInfo: async (profileId) => {
    try {
      const data = await AgodaApi.getUserInfo(profileId);
      return data.map(user => new AgodaUser(user));
    } catch (error) {
      console.error('Repository Error in getUserInfo:', error);
      if (error.response?.status === 404) {
        throw new Error('找不到使用者資料');
      }
      throw error;
    }
  },

  getSourceInfo: async (userId) => {
    try {
      const data = await AgodaApi.getSourceInfo(userId);
      return data.map(source => new AgodaSource(source));
    } catch (error) {
      console.error('Repository Error in getSourceInfo:', error);
      if (error.response?.status === 404) {
        throw new Error('找不到房源資料');
      }
      throw error;
    }
  },

  getConversationId: async (sourceId) => {
    try {
      return await AgodaApi.getConversationId(sourceId);
    } catch (error) {
      console.error('Repository Error in getConversationId:', error);
      throw error;
    }
  },

  getBookingInfo: async (conversationId) => {
    try {
      const data = await AgodaApi.getBookingInfo(conversationId);
      return data.map(booking => new AgodaBooking(booking));
    } catch (error) {
      // console.error('Repository Error in getBookingInfo:', error);
      if (error.response?.status === 404) {
        throw new Error('找不到訂單資料');
      }
      throw error;
    }
  },

  getConversationMessages: async (conversationId) => {
    try {
      const data = await AgodaApi.getConversationMessages(conversationId);
      return data.map(message => new AgodaMessage(message));
    } catch (error) {
      // console.error('Repository Error in getConversationMessages:', error);
      if (error.response?.status === 404) {
        throw new Error('找不到對話紀錄');
      }
      throw error;
    }
  }
}; 