// SearchResult entity class
export class SearchResult {
  constructor(content, type, createdAt, metadata = {}) {
    this.content = content;
    this.type = type;
    this.createdAt = createdAt;
    this.metadata = metadata;
  }

  static fromApiResponse(response, type) {
    return new SearchResult(
      response.blob?.filename || response.blob?.url || response.question || response.blob?.id,
      type,
      response.blob?.created_at,
      {
        contentType: response.blob?.content_type,
        ...response
      }
    );
  }
} 