import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Input, Button, notification } from 'antd';
import { useAuth } from '../../contexts/AuthProvider';
import Logo from '../../components/logo/Logo';

const SERVER_URL = process.env.REACT_APP_SERVER_URL;
const COMPANY_ID = process.env.REACT_APP_COMPANY_ID;
const FormData = require('form-data');

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const navigate = useNavigate();
  const { login } = useAuth();

  const formData = new FormData();

  const openNotification = (title, description, type = 'info') => {
    notification[type]({
      message: title,
      description: description,
      placement: "bottomRight",
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError(false);

    formData.append('username', username);
    formData.append('password', password);
    formData.append('client_id', username === 'admin' ? '' : COMPANY_ID);

    try {
      setLoading(true);
      const loginResponse = await axios.post(SERVER_URL + '/public/auth/signin', formData);
      const token = loginResponse.data.access_token;

      const meResponse = await axios.get(SERVER_URL + '/private/user/me', {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      const userId = meResponse.data.id;
      const userRoleId = meResponse.data.role_id;

      login(username, userId, userRoleId, token);

      navigate('/explore')
      window.location.reload();

    } catch (error) {
      openNotification('登入失敗', '請確認帳號密碼是否正確', 'error');
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center w-full h-screen">
      <div className="flex flex-col items-center justify-center w-80 gap-4">
        <Logo type="LOGIN" />
        <div className="text-2xl font-bold mb-4">TeamSync</div>
        <Input status={error && "error"} placeholder="帳號" onChange={e => setUsername(e.target.value)} disabled={loading} onPressEnter={handleSubmit} />
        <Input.Password status={error && "error"} placeholder="密碼" onChange={e => setPassword(e.target.value)} disabled={loading} onPressEnter={handleSubmit} />
        <div className="flex flex-row justify-between w-full mt-4">
          <a href="/forget">
            <Button>忘記密碼</Button>
          </a>
          <Button type="primary" onClick={handleSubmit} loading={loading}>登入</Button>
        </div>
        <div className="flex flex-row justify-end w-full text-xs">
          <div className="text-gray-500">尚未加入？</div>
          <a href="/register" className="hover:underline">馬上註冊</a>
        </div>
      </div>
    </div>
  )
}

export default Login