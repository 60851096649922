import React, { useState } from 'react';
import { Modal, Input, Button, Form, App } from 'antd';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../../../../contexts/UserProvider';

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

// Join Chat Room Component
const JoinChat = ({ token, isModalOpen, closeModal }) => {
  const [roomId, setRoomId] = useState('');
  const [loading, setLoading] = useState(false);
  const [showPasswordInput, setShowPasswordInput] = useState(false);
  const [password, setPassword] = useState('');
  const [roomIdError, setRoomIdError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const navigate = useNavigate();
  const { user } = useUser();
  const { message } = App.useApp();
  // Reset states when modal closes

  const handleClose = () => {
    setRoomId('');
    setPassword('');
    setShowPasswordInput(false);
    setLoading(false);
    setRoomIdError('');
    setPasswordError('');
    closeModal();
  };

  // Check if room exists and its type
  const checkRoom = async (roomId) => {
    try {
      const response = await axios.get(`${SERVER_URL}/private/chatrooms/${roomId}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response?.data?.detail || '找不到聊天室');
    }
  };

  // Handle joining chat room
  const handleJoin = async () => {
    if (!roomId.trim()) {
      message.error('請輸入聊天室 ID');
      return;
    }
    setLoading(true);
    setRoomIdError('');
    setPasswordError('');
    try {
      // Check if user has already joined
      const hasJoined = user.joined_rooms.includes(roomId);
      if (hasJoined) {
        navigate(`/chat/${roomId}`);        
        window.location.reload();
        return;
      }

      // Check room existence and type
      const roomInfo = await checkRoom(roomId);

      if ( user.role_id === 4) {
        message.success('成功加入新私人聊天室');
        setTimeout(() => {
          navigate(`/chat/${roomId}`);
          window.location.reload();
        }, 1000);
        return;
      }
      // If room is private and password input is not shown yet
      if (!roomInfo.is_public && !showPasswordInput) {
        setShowPasswordInput(true);
        setLoading(false);
        return;
      }
      // Join the chat room
      const response = await axios.post(`${SERVER_URL}/private/chatrooms/join/${roomId}`, {
        text: password ? password : ''
      }, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
      });

      if (response.status === 200) {
        message.success('成功加入新公開聊天室');
        handleClose();
        setTimeout(() => {
          navigate(`/chat/${roomId}`);
          window.location.reload();
        }, 1000);
      } else if (response.status === 400) {
        if (response.data.detail === 'You are already a member of this chatroom') {
          navigate(`/chat/${roomId}`);
          handleClose();
        } else {
          throw new Error(response.data);
        }
      } else {
        throw new Error(response.data);
      }
    } catch (error) {
      console.log('error', error);
      const errorMessage = error.message || error.response?.data?.detail;
      if (errorMessage?.includes('Chatroom Not found')) {
        setRoomIdError('查無此ID，請重新輸入');
        setPasswordError('');
      } else if (error.response?.data?.detail.includes('Invalid passphrase')) {
        setPasswordError('密碼錯誤，請重新輸入');
        setRoomIdError('');
      } else {
        // setRoomIdError(error.response?.data?.detail || '加入聊天室失敗');
        setPasswordError('');
        setRoomIdError('加入聊天室失敗');
      }
      
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      width={400}
      open={isModalOpen}
      onCancel={handleClose}
      footer={null}
    >
      <div className="flex flex-col items-center mt-4">
        <div className="text-xl font-bold">加入聊天室</div>
        <div className="text-sm text-textLight">加入其他人的聊天室</div>

        <div className="flex flex-col items-start w-full mt-4 px-4">
          <Form className="w-full" onFinish={handleJoin}>
            {
              !showPasswordInput && (
                <>
                  <div className="text-textDark">輸入聊天室 ID</div>
                  <Input
                    className="w-full my-2"
                    value={roomId}
                    onChange={e => {
                      setRoomId(e.target.value);
                      setRoomIdError('');
                    }}
                    placeholder="請輸入聊天室 ID"
                    disabled={showPasswordInput}
                    status={roomIdError ? 'error' : ''}
                  />
                  <div className="h-6">
                    {roomIdError && (
                      <div className="text-red-500 text-sm">{roomIdError}</div>
                    )}
                  </div>
                </>
              )
            }

            {showPasswordInput && (
              <>
                <div className="text-textDark mt-4">輸入密碼</div>
                <Input.Password
                  className="w-full my-2"
                  value={password}
                  onChange={e => {
                    setPassword(e.target.value);
                    setPasswordError('');
                  }}
                  placeholder="請輸入聊天室密碼"
                  status={passwordError ? 'error' : ''}
                />
                <div className="h-6">
                  {passwordError && (
                    <div className="text-red-500 text-sm">{passwordError}</div>
                  )}
                </div>
              </>
            )}
            

            <div className="flex justify-end w-full gap-4">
            {
                showPasswordInput && (
                  <Button
                    type="primary"
                    className="my-4"
                    onClick={() => setShowPasswordInput(false)}
                    disabled={loading}
                  >
                    返回
                  </Button>
                )
              }
              <Button
                type="primary"
                className="my-4"
                onClick={handleJoin}
                loading={loading}
              >
                確認
              </Button>
              
            </div>
          </Form>
        </div>
      </div>
    </Modal>
  );
};

export default JoinChat; 