/**
 * @interface ISearchRepository
 */
export class ISearchRepository {
  /**
   * Search for general memories
   * @param {string} chatroomId
   * @param {string} keyword
   * @param {number} limit
   * @returns {Promise<Array>}
   */
  searchGeneralMemories(chatroomId, keyword, limit) {
    throw new Error('Method not implemented');
  }

  /**
   * Search for advanced memories
   * @param {string} chatroomId
   * @param {string} keyword
   * @param {number} limit
   * @returns {Promise<Array>}
   */
  searchAdvancedMemories(chatroomId, keyword, limit) {
    throw new Error('Method not implemented');
  }

  /**
   * Search for QA memories
   * @param {string} chatroomId
   * @param {string} keyword
   * @param {number} limit
   * @returns {Promise<Array>}
   */
  searchQAMemories(chatroomId, keyword, limit) {
    throw new Error('Method not implemented');
  }
} 