import request from '../../utils/request';

/**
 * Get available chat models
 * @returns {Promise<Object>} Object containing model IDs as keys and display names as values
 */
export const getChatModels = async () => {
  try {
    return await request.get('/public/info/chat_models');
  } catch (error) {
    throw error;
  }
};

/**
 * Get chatroom model settings
 * @param {string} chatroomId - The ID of the chatroom
 * @returns {Promise<Object>} Chatroom model settings
 */
export const getChatroomModelSettings = async (chatroomId) => {
  try {
    return await request.get(`/private/chatrooms/chat/settings/${chatroomId}/model`);
  } catch (error) {
    throw error;
  }
};

/**
 * Get available text to image models
 * @returns {Promise<Object>} Object containing model IDs as keys and display names as values
 */
export const getTextToImageModels = async () => {
  try {
    return await request.get('/public/info/text_to_image_models');
  } catch (error) {
    throw error;
  }
};

/**
 * Update chatroom model settings
 * @param {string} chatroomId - The ID of the chatroom
 * @param {Object} modelSettings - The model settings to update
 * @param {string} modelSettings.model - The model ID
 * @param {Object} modelSettings.model_args - The model arguments
 * @param {string} modelSettings.text_to_image_model - The text to image model ID (optional)
 * @returns {Promise<Object>} Updated chatroom model settings
 */
export const updateChatroomModelSettings = async (chatroomId, modelSettings) => {
  try {
    return await request.put(`/private/chatrooms/chat/settings/${chatroomId}/model`, modelSettings);
  } catch (error) {
    throw error;
  }
}; 