import request from '../../utils/request';

/**
 * Update chatroom chat settings
 * @param {string} chatroomId - The ID of the chatroom
 * @param {Object} settings - The settings to update
 * @param {boolean} settings.web_search - Enable/disable web search
 * @param {boolean} settings.as_graph - Enable/disable graph mode
 * @param {string} settings.rag_mode - RAG mode setting
 * @param {boolean} settings.bypass_prompt_optimization - Enable/disable prompt optimization
 * @returns {Promise} API response
 */
export const updateChatroomChatSettings = async (chatroomId, settings) => {
  try {
    return await request.put(
      `/private/chatrooms/chat/settings/${chatroomId}`,
      settings
    );
  } catch (error) {
    throw error;
  }
};
