import { useState } from 'react';
import { message } from 'antd';
import * as settingFacade from '../application/facades/SettingFacade';

/**
 * Custom hook for handling chatroom settings
 * @returns {Object} Hook methods and state
 */
export const useSetting = () => {
  const [loading, setLoading] = useState(false);

  /**
   * Update chatroom chat settings
   * @param {string} chatroomId - The ID of the chatroom
   * @param {Object} settings - The settings to update
   * @param {boolean} settings.web_search - Enable/disable web search
   * @param {boolean} settings.as_graph - Enable/disable graph mode
   * @param {string} settings.rag_mode - RAG mode setting
   * @param {boolean} settings.bypass_prompt_optimization - Enable/disable prompt optimization
   */
  const updateChatroomChatSettings = async (chatroomId, settings) => {
    try {
      setLoading(true);
      await settingFacade.updateChatroomChatSettings(chatroomId, settings);
    } catch (error) {
      message.error(error.message || '更新聊天室設定失敗');
      throw error;
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    updateChatroomChatSettings
  };
};
