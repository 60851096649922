import memoryService from '../service/memoryService';
import boundService from '../service/boundService';

import { configureStore } from '@reduxjs/toolkit';

const store = configureStore({
    reducer: {
        memory: memoryService, 
        bound: boundService,
    },
});

export default store;
