import React, { useState } from "react";
import { Button, Input } from "antd";
import { ReactComponent as MessengerIcon } from "../../assets/messenger.svg";

const SocialSetting = () => {
  const [pages, setPages] = useState([]);
  const [linkedPages, setLinkedPages] = useState([]);

  const appId = "9192373887458157";
  const redirectUri = `${window.location.origin}/fb_oauth`;
  const appSecret = "d281749b475756375d7c3fc1f016862b";
  const SCOPE =
    "pages_manage_ads,pages_manage_metadata,pages_read_engagement,pages_read_user_content,business_management,pages_messaging,pages_show_list,pages_manage_posts,pages_manage_engagement,ads_management,page_events";

  const openFBAuthPopup = () => {
    const oauthURL = `https://www.facebook.com/v19.0/dialog/oauth?client_id=${appId}&redirect_uri=${redirectUri}&scope=${SCOPE}&response_type=code&display=popup&auth_type=rerequest`;

    const popup = window.open(oauthURL, "fbLoginPopup", "width=600,height=700");

    const checkPopup = setInterval(() => {
      if (popup.closed) {
        clearInterval(checkPopup);
      } else {
        try {
          const currentUrl = popup.location.href;
          if (currentUrl.includes(redirectUri)) {
            popup.close();
            clearInterval(checkPopup);
            const urlParams = new URL(currentUrl).searchParams;
            const code = urlParams.get("code");
            if (code) {
              getAccessToken(code);
            }
          }
        } catch (error) {
          // 跨域錯誤可以忽略
        }
      }
    }, 500);

    window.addEventListener("message", (event) => {
      if (event.origin !== redirectUri) return;
      if (event.data.type === "FB_AUTH_SUCCESS") {
        console.log("獲取到 Facebook 授權碼:", event.data.code);
        getAccessToken(event.data.code);
      }
    });
  };

  const getAccessToken = async (code) => {
    const url = `https://graph.facebook.com/v19.0/oauth/access_token?client_id=${appId}&redirect_uri=${redirectUri}&client_secret=${appSecret}&code=${code}`;

    try {
      const response = await fetch(url);
      const data = await response.json();
      console.log("Access Token:", data.access_token);
      getPageList(data.access_token);
    } catch (error) {
      console.error("Error fetching access token:", error);
    }
  };

  const getPageList = async (accessToken) => {
    const url = `https://graph.facebook.com/v22.0/me/accounts?access_token=${accessToken}`;
    try {
      const response = await fetch(url);
      const data = await response.json();

      if (data.data && data.data.length > 0) {
        setPages(data.data);
      }
    } catch (error) {
      console.error("Error fetching page list:", error);
    }
  };

  const handleLinkPage = (pageId) => {
    setLinkedPages([...linkedPages, pageId]);
  };

  const handleRemovePage = (pageId) => {
    setLinkedPages(linkedPages.filter((id) => id !== pageId));
  };

  return (
    <div className="flex flex-col justify-start items-start w-full px-4 py-6 gap-4">
      <div className="text-lg text-textDark font-bold w-full">社群串接</div>

      <div className="w-full space-y-4">
        <div className="w-full bg-[#f5f5f5] border border-borderLight p-4 rounded-sm">
          <div className="flex items-center justify-between w-full ">
            <div className="flex items-center">
              <MessengerIcon className="w-4 h-4 mr-3" />
              <span className="text-[17px] font-normal leading-[130%] ">
                Facebook
              </span>
            </div>
            <div className="flex items-center gap-2">
              <Button type="primary" onClick={openFBAuthPopup} className="mt-2">
                連結Facebook
              </Button>
              {linkedPages.length > 0 && (
                <Button
                  type="primary"
                  onClick={() =>
                    window.open(
                      "https://www.facebook.com/pages/creation/",
                      "_blank"
                    )
                  }
                  className="mt-2"
                >
                  新增粉絲專頁
                </Button>
              )}
            </div>
          </div>
          <div className="w-full h-[1px] bg-borderLight mt-4"></div>
          {pages.length === 0 ? (
            <div className="text-[#B8B8B8] mt-2 text-[14px]">尚未連結</div>
          ) : (
            <ul className="space-y-6 mt-4">
              {pages.map((page) => (
                <li key={page.id} className="flex flex-col">
                  <div className="flex items-center justify-between w-full">
                    <div className="flex items-center gap-4 w-full">
                      <div className="w-8 h-8 flex-shrink-0 self-start bg-[#D9D9D9] overflow-hidden rounded-full flex items-center justify-center text-gray-600">
                        <img
                          src={`https://graph.facebook.com/v18.0/${page?.id}/picture`}
                          alt="粉專大頭"
                          className="w-full h-full object-cover"
                        />
                      </div>
                      <div className="flex flex-col gap-2 w-full">
                        <div className="flex items-center">
                          <span className="font-normal text-[#3E3D3D] text-[14px] leading-[130%] flex-1 content-center">
                            {page?.name}
                          </span>
                          {linkedPages.includes(page.id) ? (
                            <Button
                              type="default"
                              danger
                              onClick={() => handleRemovePage(page.id)}
                            >
                              移除
                            </Button>
                          ) : (
                            <Button
                              type="default"
                              onClick={() => handleLinkPage(page.id)}
                            >
                              連結
                            </Button>
                          )}
                        </div>
                        <div className="flex items-center gap-2">
                          <Input
                            value={`https://www.facebook.com/profile.php?id=${page?.id}`}
                            className="font-normal text-[10px] leading-[130%] disabled:bg-[#F7F7F7] w-3/5 "
                          />
                          {linkedPages.includes(page.id) && (
                            <span className="text-[#E69200]   font-normal text-[10px] leading-[130%] ">
                              ✓ 已連結
                            </span>
                          )}
                        </div>
                        <div className="flex items-center gap-2 ">
                          <div className="w-7 h-7 flex-shrink-0 bg-[#D9D9D9] overflow-hidden rounded-full flex items-center justify-center text-xs text-gray-600">
                            管理員
                          </div>
                          <span className="font-normal text-[#3E3D3D] text-[14px] leading-[130%] ">
                            管理員FB名字
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </div>
  );
};

export default SocialSetting;