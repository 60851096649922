import * as modelRepository from '../../infrastructure/repositories/ModelRepository';

/**
 * Get available chat models
 * @returns {Promise<Object>} Object containing model IDs as keys and display names as values
 */
export const getChatModels = async () => {
  try {
    // Get models from repository
    const response = await modelRepository.getChatModels();
    
    // Process the response if needed
    // For now, we're just passing it through
    return response;
  } catch (error) {
    throw error;
  }
};

/**
 * Get chatroom model settings
 * @param {string} chatroomId - The ID of the chatroom
 * @returns {Promise<Object>} Chatroom model settings
 */
export const getChatroomModelSettings = async (chatroomId) => {
  try {
    if (!chatroomId) {
      throw new Error('Chatroom ID is required');
    }
    
    const response = await modelRepository.getChatroomModelSettings(chatroomId);
    
    // Process the response if needed
    return response;
  } catch (error) {
    throw error;
  }
};

/**
 * Get available text to image models
 * @returns {Promise<Object>} Object containing model IDs as keys and display names as values
 */
export const getTextToImageModels = async () => {
  try {
    // Get models from repository
    const response = await modelRepository.getTextToImageModels();
    
    // Process the response if needed
    // For now, we're just passing it through
    return response;
  } catch (error) {
    throw error;
  }
};

/**
 * Update chatroom model settings
 * @param {string} chatroomId - The ID of the chatroom
 * @param {string} modelId - The model ID
 * @param {Object} modelArgs - The model arguments (optional)
 * @param {string} textToImageModelId - The text to image model ID (optional)
 * @returns {Promise<Object>} Updated chatroom model settings
 */
export const updateChatroomModelSettings = async (chatroomId, modelId, modelArgs = {}, textToImageModelId = null) => {
  try {
    if (!chatroomId) {
      throw new Error('Chatroom ID is required');
    }
    
    if (!modelId) {
      throw new Error('Model ID is required');
    }
    
    const modelSettings = {
      model: modelId,
      model_args: modelArgs
    };

    // 如果有提供文字轉圖片模型ID，則添加到設定中
    if (textToImageModelId) {
      modelSettings.text_to_image_model = textToImageModelId;
    }
    
    return await modelRepository.updateChatroomModelSettings(chatroomId, modelSettings);
  } catch (error) {
    throw error;
  }
}; 