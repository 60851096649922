import React, { useState, useEffect } from "react";
import { Button } from "antd";
import { useNotification } from "../../../../../../contexts/NotificationContext";


const NotificationSetting = ({ roomId, inputLabel, limitText, maxInput, unit }) => {
  const { setConfirmedDataForRoom, getConfirmedDataForRoom } = useNotification();
  const [isEditing, setIsEditing] = useState(false);
  const [inputData, setInputData] = useState({ value: null, content: "" });
  const [confirmedData, setConfirmedData] = useState(null);


  const handleConfirm = () => {
    setConfirmedData(inputData);
    setConfirmedDataForRoom(roomId, inputLabel, inputData);
    setIsEditing(false);
  };

  useEffect(() => {
    const data = getConfirmedDataForRoom(roomId, inputLabel);
    if (data) {
      setConfirmedData(data);
      setInputData(data);
    }
  }, [roomId, inputLabel, getConfirmedDataForRoom]);

  return (
    <>
      <div className="text-[16px] font-normal">
      {isEditing ? (
        <div>
          <div className="flex items-center">
            <span>{inputLabel}</span>
            {maxInput && (
              <input
              type="text"
              className="leading-[28px] mx-2 rounded border-[2px] text-center w-[55px] h-[26px]"
              value={inputData.value || ""}
              onChange={(e) => {
                const value = e.target.value;
                if (/^\d*$/.test(value)) { // 只允許數字
                  setInputData({ ...inputData, value });
                }
              }}
              required
            />
            )}
            <span>{unit} 發送訊息</span>
          </div>
          {limitText && (
            <div className="text-red-500 mb-2 text-[14px]">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{limitText}</div>
          )}
          <div className="mb-1 mt-3 text-[14px]">內容</div>
          <textarea
            className="w-full h-[140px] mb-2 rounded border-[2px] px-1"
            value={inputData.content}
            onChange={(e) => setInputData({ ...inputData, content: e.target.value })}
          />
          <div className="w-full flex justify-end gap-4">
            <Button
              className="text-black-900 text-[16px] leading-[1.3]"
              onClick={() => setIsEditing(false)}
            >
              取消
            </Button>
            <Button
              className="text-black-900 text-[16px] leading-[1.3]"
              style={{ backgroundColor: "#ADDEAC" }}
              onClick={handleConfirm}
            >
              確認
            </Button>
          </div>
        </div>
      ) : confirmedData ? (
        <>
          <div className="flex justify-between items-center">
            <div className="flex items-center">
              <span>{inputLabel}</span>
              {maxInput && (
                <span className="text-black mx-2 font-[500] w-[55px] text-center">{confirmedData.value}</span>
              )}
              <span>{unit} 發送訊息</span>
            </div>
            <button onClick={() => setIsEditing(true)}>
              <img src="/images/edit-icon.png" alt="edit-icon" />
            </button>
          </div>
          <div className="mt-3 mb-1 text-[14px]">內容</div>
          <textarea
            className="bg-[#E8E8E8] h-[140px] rounded border-[2px] w-full px-1"
            value={inputData.content}
            disabled
          >
            <p className="text-gray-600 mx-1">{confirmedData.content}</p>
          </textarea>
        </>
      ) : (
        <div className="flex justify-between items-center">
          <div className="flex items-center">
            <span>{inputLabel}</span>
            {maxInput && (
              <span className="text-gray-400 m-2">(未指定)</span>
            )}
            <span>{unit} 發送訊息</span>
          </div>
          <button onClick={() => setIsEditing(true)}>
            <img src="/images/add-icon.png" alt="add-icon" />
          </button>
        </div>
      )}
    </div>
    </>
  );
};

export default NotificationSetting;
