import React, { useEffect, useState } from "react";
import { Select, Spin } from "antd";
import BoundListItem from "./BoundListItem";
import { useBound } from "../../../../contexts/BoundContext";
import SearchBar from "../../../../components/SearchBar";
import { SearchFacade } from "../../../../application/facades/SearchFacade";
import { LoadingOutlined } from "@ant-design/icons";
const BoundList = ({
  activeId,
  setActiveId,
  activeTab,
  onChangeTab,
  newMessageClientList,
  clients,
  setClientsAgoda,
  setClients,
  roomId,
  room,
  loading
}) => {
  const { airbnbMessage, agodaMessage } = useBound();
  const [aiFilter, setAiFilter] = useState('all');
  const [searchText, setSearchText] = useState('');
  const [searchRange, setSearchRange] = useState('全部');
  const [filteredClients, setFilteredClients] = useState([]);
  const searchFacade = new SearchFacade();
    
  const boundSearchOptions = [
    { value: '全部', label: '全部' },
    { value: '名稱', label: '名稱' },
    { value: '別名', label: '別名' },
    { value: '訊息', label: '訊息' },
    { value: '備註', label: '備註' },
  ];

  // 本地搜尋函數
  const localSearch = (clientList, searchText, searchRange) => {
    const searchLower = searchText.toLowerCase();
    return clientList.filter(client => {
      switch (searchRange) {
        case '全部':
          return (
            (client.displayName && client.displayName.toLowerCase().includes(searchLower)) ||
            (client?.nickname && client?.nickname.toLowerCase().includes(searchLower)) ||
            (client.last_two_messages && 
             client.last_two_messages[0]?.message &&
             client.last_two_messages[0].message.toLowerCase().includes(searchLower)) ||
            (client.notes && Array.isArray(client.notes) && 
             client.notes.some(note => note.content && 
             note.content.toLowerCase().includes(searchLower)))
          );
        case '名稱':
          return client.displayName && 
                 client.displayName.toLowerCase().includes(searchLower);
        case '別名':
          return client.nickname && 
                 client.nickname.toLowerCase().includes(searchLower);
        case '訊息':
          return client.last_two_messages && 
                 client.last_two_messages[0]?.message &&
                 client.last_two_messages[0].message.toLowerCase().includes(searchLower);
        case '備註':
          return client.notes && 
                 Array.isArray(client.notes) && 
                 client.notes.some(note => 
                   note.content && 
                   note.content.toLowerCase().includes(searchLower)
                 );
        default:
          return true;
      }
    });
  };

  // Handle search and filtering
  const handleSearch = async (text, range) => {
    setSearchText(text);
    setSearchRange(range);
    
    if (!clients) return;
    
    if (text && text.trim() !== '') {
      try {
        // 如果是訊息搜尋或全部搜尋，則使用API
        if (range === '訊息' || range === '全部') {
          console.log('BoundList searchText:', text);
          const searchResults = await searchFacade.searchClients(roomId, text);
          console.log('API search results:', searchResults);
          
          let apiFilteredResults = [];
          if (searchResults && Array.isArray(searchResults)) {
            apiFilteredResults = clients.filter(client => 
              searchResults.some(result => {
                const [externalId] = result.split(':');
                return client.external_id === externalId;
              })
            );
          }

          if (range === '全部') {
            // 合併API搜尋和本地搜尋結果
            const localResults = localSearch(clients, text, range);
            // 使用 Set 來去除重複項
            const combinedResults = Array.from(new Set([...apiFilteredResults, ...localResults]));
            setFilteredClients(combinedResults);
          } else {
            setFilteredClients(apiFilteredResults);
          }
        } else {
          // 其他類型使用本地搜尋
          const localResults = localSearch(clients, text, range);
          setFilteredClients(localResults);
        }
      } catch (error) {
        console.error('搜尋失敗:', error);
        // 發生錯誤時退回到本地搜尋
        const localResults = localSearch(clients, text, range);
        setFilteredClients(localResults);
      }
    } else {
      setFilteredClients(clients);
    }
  };

  // Filter clients based on search criteria
  useEffect(() => {
    if (!clients) return;
    if (!searchText || searchText.trim() === '') {
      setFilteredClients(clients);
      return;
    }
  }, [searchText, searchRange, clients]);

  useEffect(() => {
    if (activeTab === 0) {
      if (agodaMessage && agodaMessage.length > 0) {
        setClientsAgoda([]);
        const res = processOneAgodaMessage(agodaMessage, roomId);
        setClientsAgoda(res);
      }
    } else if (activeTab === 1) {
      if (clients && clients.length > 0) {
        setClients([]);
        setClients(clients);
      }
    }
  }, [airbnbMessage, agodaMessage, activeTab]);

  const processOneAgodaMessage = (data, roomId, guestName) => {
    // check if data is an array

    const rawDataArray = Array.isArray(data) ? data : [data];
    // store processed messages
    let processedMessages = [];
    

    rawDataArray.forEach((rawData) => {
      if (!Array.isArray(rawData.result)) {
        return
      }
      if (rawData && rawData.result && Array.isArray(rawData.result) && rawData.result.length > 0) {
        const messages = rawData?.result;
        const senderWithReservation = messages.find(
          (message) =>
            message.sender_role && message.sender_role.includes("guest")
        );
        // const displayName = guestName;
        const pictureUrl = senderWithReservation
          ? senderWithReservation.sender_photo_url
          : null;

        // find the last message with body
        let lastMessage = null;
        for (let i = messages.length - 1; i >= 0; i--) {
          if (messages[i].body) {
            lastMessage = messages[i];
            break;
          }
        }

        if (!lastMessage) {
          lastMessage = {
            body: "No messages",
            send_date: "",
            sender_role: "",
          };
        }

        const processedMessage = {
          userId: rawData.message_id || null,
          messageId: rawData.message_id || null,
          roomId: roomId || null,
          // displayName: rawData.get_order_info.result.guest_details[0][1] || null,
          pictureUrl: pictureUrl || null,
          channel: "agoda",
          last_two_messages: [
            {
              created_at: lastMessage.send_date || null,
              message: lastMessage.body || null,
            },
          ],
          unreadCount: 1,
        };

        processedMessages.push(processedMessage);
      } else {
        console.warn('Invalid rawData or rawData.result structure:', rawData);
      }
    });

    return processedMessages;
  };

  const handleAiFilter = (value) => {
    setAiFilter(value);
  };

  const renderContent = () => {
    if (loading) {
      return (
        <div className="flex justify-center items-center w-full h-screen">
          <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
        </div>
      );
    }
    

    if (clients.length <= 0) {
      return (
        <div className="w-full flex flex-col gap-4 items-center justify-center pt-12">
          這裡沒有任何第三方紀錄。
        </div>
      );
    }

    // 如果有搜尋文字但沒有搜尋結果
    if (searchText && filteredClients.length === 0) {
      return (
        <div className="w-full flex flex-col gap-4 items-center justify-center pt-12">
          <div className="text-gray-500">
            找不到符合「{searchText}」的{searchRange === '全部' ? '內容' : searchRange}
          </div>
        </div>
      );
    }

    // 有搜尋結果或未搜尋時顯示列表
    return (
      <div className="flex flex-col overflow-y-scroll pb-12 overflow-x-hidden">
        {(filteredClients.length > 0 ? filteredClients : clients)
          .sort(
            (a, b) =>
              new Date(b.last_two_messages[0]?.created_at) -
              new Date(a.last_two_messages[0]?.created_at)
          )
          .map((client, index) => (
            <BoundListItem
              key={index}
              client={client}
              activeId={activeId}
              setActiveId={setActiveId}
              newMessage={newMessageClientList.includes(client.userId)}
              num_of_unseen_messages={client.num_of_unseen_messages}
              room={room}
              setClients={setClients}
            />
          ))}
      </div>
    );
  };

  return (
    <div className="pr-3 flex flex-col min-w-72 max-w-72 bg-bgLight px-2 pt-6 gap-2">
      <div className="flex items-center w-full max-w-md relative">
        <SearchBar
          placeholder="依照範圍搜尋"
          onSearch={handleSearch}
          options={boundSearchOptions}
          defaultSearchRange="全部"
        />
      </div>
      
      {/* Container divided into three columns */}
      <div className="grid grid-cols-10 gap-2 w-full">
        <div className="col-span-4">
          <Select
            value={activeTab}
            onChange={onChangeTab}
            className="!border-none w-full bg-[#F5F5F5]"
            variant="borderless"
            suffixIcon={<span className="text-gray-400">▼</span>}
          >
            <Select.Option value={0}>Agoda</Select.Option>
            <Select.Option value={1}>Line</Select.Option>
          </Select>
        </div>
        
        <div className="col-span-5">
          <Select
            value={aiFilter}
            onChange={handleAiFilter}
            className="!border-none w-full bg-[#F5F5F5]"
            variant="borderless"
            suffixIcon={<span className="text-gray-400">▼</span>}
          >
            <Select.Option value="all">所有聊天室</Select.Option>
            <Select.Option value="line">轉真人聊天室</Select.Option>
          </Select>
        </div>
        
        <div className="col-span-1">
        </div>
      </div>

      {renderContent()}
    </div>
  );
};

export default BoundList;
