import * as settingRepository from '../../infrastructure/repositories/SettingRepository';

/**
 * Update chatroom chat settings
 * @param {string} chatroomId - The ID of the chatroom
 * @param {Object} settings - The settings to update
 * @param {boolean} settings.web_search - Enable/disable web search
 * @param {boolean} settings.as_graph - Enable/disable graph mode
 * @param {string} settings.rag_mode - RAG mode setting
 * @param {boolean} settings.bypass_prompt_optimization - Enable/disable prompt optimization
 * @returns {Promise} Use case response
 */
export const updateChatroomChatSettings = async (chatroomId, settings) => {
  try {
    // Validate settings
    if (typeof settings.web_search !== 'boolean') {
      throw new Error('web_search must be a boolean');
    }
    if (typeof settings.as_graph !== 'boolean') {
      throw new Error('as_graph must be a boolean');
    }
    if (typeof settings.rag_mode !== 'string') {
      throw new Error('rag_mode must be a string');
    }
    if (typeof settings.bypass_prompt_optimization !== 'boolean') {
      throw new Error('bypass_prompt_optimization must be a boolean');
    }

    return await settingRepository.updateChatroomChatSettings(chatroomId, settings);
  } catch (error) {
    throw error;
  }
};
