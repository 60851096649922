import { SearchRepository } from '../../infrastructure/repositories/SearchRepository';
import { SearchUseCase } from '../../application/useCases/SearchUseCase';

export class SearchFacade {
  constructor() {
    this.searchRepository = new SearchRepository();
    this.searchUseCase = new SearchUseCase(this.searchRepository);
  }

  /**
   * Execute search operation
   * @param {string} chatroomId - Chatroom ID
   * @param {string} keyword - Search keyword
   * @param {string} searchRange - Search range ('全部搜尋' | '一般記憶' | '進階記憶' | '問答記憶')
   * @returns {Promise<Array>} Search results
   */
  async search(chatroomId, keyword, searchRange) {
    try {
      return await this.searchUseCase.executeSearch(chatroomId, keyword, searchRange);
    } catch (error) {
      console.error('Search facade error:', error);
      throw error;
    }
  }
} 