
import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { message } from 'antd';

import { useUser } from '../../contexts/UserProvider';
import { useAuth } from '../../contexts/AuthProvider';

import { ReactComponent as AddAccount } from './assets/addNewAcc_icon.svg';

import SettingMenu from './components/SettingMenu'
import AcMgnt from './components/AcMgnt'
import RmMgnt from './components/RmMgnt'
import ComMgnt from './components/ComMgnt'
import DepMgnt from './components/DepMgnt'
import SysMgnt from './components/SysMgnt'
import CreateDep from './components/CreateDep'
import AddNewAcc from './components/AddNewAcc'
import UserAvatar from '../settings/UserAvatar';
import PendingList from './components/PendingList'
import BatchAddAcc from './components/BatchAddAcc'

const Settings = () => {
  const [tab, setTab] = useState(5);
  const [isPopUp, setIsPopUp] = useState(false);
  const [departments, setDepartments] = useState([]);
  const [departmentUsers, setDepartmentUsers] = useState({});
  const [createdRooms, setCreatedRooms] = useState({});
  const [selectedDepartmentId, setSelectedDepartmentId] = useState(null);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [selectedRoomId, setSelectedRoomId] = useState(null);
  const [isSystemPage, setSystemPage] = useState(true);

  const { user } = useUser();
  const { userId, token } = useAuth();

  const SERVER_URL = process.env.REACT_APP_SERVER_URL;
  const changeTab = (tab, departmentId, userId, roomId) => {
    if (tab !== 5 && tab !== 7 && tab !== 8) {
      setTab(tab);
    } else if (tab === 5 || tab === 7 || tab === 8) {
      setTab(tab);
    }
    if (departmentId) setSelectedDepartmentId(departmentId);
    if (userId) setSelectedUserId(userId);
    if (roomId) setSelectedRoomId(roomId);
    setIsPopUp(false);
  };

  useEffect(() => {
    setSystemPage(true);
  }, []);

  const handleAddNewAccount = () => {
    setIsPopUp(true);
  };
  const closePopUp = () => {
    setIsPopUp(false);
  };
  // Fetch all departments
  const fetchDepartments = async () => {
    if (!user || !token) return;
    try {
      if (user.role_id === 4) {
        const response = await axios.get(`${SERVER_URL}/private/company/departments/`, {
          headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        });
        if (response.status === 200) {
          const data = response.data;
          setDepartments(data);
        } else {
          throw new Error('Failed to fetch departments');
        }
      } else {
        const myDepartment = {
          id: user.department_id,
          name: user.department_name
        };
        setDepartments([myDepartment]);
      }
    } catch (error) {
      message.error(message.error.response.data.detail);
    }
  };

  //set departments users
  const fetchDepartmentUsers = async (departmentId) => {
    try {
      if (user.role_id === 4) {
        const response = await axios.get(`${SERVER_URL}/private/company/users/department/${departmentId}`, {
          headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        });
        if (response.status === 200) {
          const data = response.data;
          setDepartmentUsers((prev) => ({ ...prev, [departmentId]: data }));
        } else {
          throw new Error('Failed to fetch department users');
        }
      } else {
        const inMyDepartment = { [user.department_id]: [user] };
        setDepartmentUsers(inMyDepartment);
        // console.log('inMyDepartment::::', inMyDepartment);
      }

    } catch (error) {
      message.error('Error fetching department users:', error.response.data.detail);
    } finally {

    }
  };

  //fetch each user created rooms
  const fetchRoomDetails = async (userId) => {
    try {
      const response = await axios.get(`${SERVER_URL}/private/chatrooms/list/${userId}`, {
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });

      // if (response.status !== 200) throw new Error(`Failed to fetch rooms for user ${userId}`);
      const data = response.data;
      setCreatedRooms((prev) => ({ ...prev, [userId]: data }));
      // console.log('createdRooms::::', createdRooms)
    } catch (error) {
      message.error('Error fetching room details:', error.response.data.detail);
    }
  };

  //if user role id is not 4
  const ownedChatRooms = async () => {
    try {
      const response = await axios.get(`${SERVER_URL}/private/chatrooms/list/my`, {
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });
      if (response.status !== 200) throw new Error(`Failed to fetch rooms for user ${user.id}`);
      const data = response.data;
      const myRooms = {
        [user.id]: data
      }
      setCreatedRooms(myRooms);
    } catch (error) {
      console.log('Error fetching room details:', error);
    }
  };

  useEffect(() => {
    fetchDepartments();
  }, [user, userId]);

  useEffect(() => {
    if (departments.length > 0) {
      departments.forEach((department) => {
        fetchDepartmentUsers(department.id);
      });
    }
  }, [departments]);


  useEffect(() => {
    if (Object.keys(departmentUsers).length > 0 && user.role_id >= 4) {
      Object.values(departmentUsers).forEach((users) => {
        users.forEach((user) => {
          if (user.created_rooms && user.created_rooms.length > 0) {
            fetchRoomDetails(user.id);
          }
        });
      });
    } else {
      if (user && user.id) {
        ownedChatRooms();
      }
    }
  }, [departmentUsers]);

  // if (isLoading || loading) {
  //   return <div className='flex items-center justify-center w-full'>
  //     <Spin
  //       indicator={
  //         <LoadingOutlined
  //           style={{
  //             fontSize: 48,
  //           }}
  //           spin
  //         />
  //       }
  //     />
  //   </div>
  // };


  return (
    <>
      <div className="flex flex-row w-full h-screen z-0 ">
        <SettingMenu changeTab={changeTab} currentTab={tab} />
        <div className='flex flex-col w-full'>
          <div className='flex justify-between items-center w-full pl-3 pr-6 border-b-gray border h-14'>
            <p className='font-bold text-xl'>{tab === 7 ? '待審核名單' : '系統管理'}</p>
            <UserAvatar isSystemPage={isSystemPage} />
          </div>
          {/* show + icon */}
          {tab === 5 && user && user.role_id === 4 ?
            < button className='flex justify-end w-full p-4'>
              <AddAccount onClick={handleAddNewAccount} />
            </button>
            :
            null}
          {/* show popup window */}
          {isPopUp && (
            <>
              <div className="fixed inset-0 bg-black bg-opacity-50 z-10"></div>
              <div className='fixed top-[30%] right-[30%] z-10 w-[437px] h-[300px]'>
                <AddNewAcc onClose={closePopUp} changeTab={changeTab} />
              </div>
            </>)}

          {tab === 0 && <AcMgnt
            selectedUserId={selectedUserId}
            changeTab={changeTab} />}
          {tab === 1 && <RmMgnt
            selectedRoomId={selectedRoomId}
            changeTab={changeTab} />}
          {tab === 2 && <ComMgnt />}
          {tab === 3 && <DepMgnt selectedDepartmentId={selectedDepartmentId} />}
          {/* {tab === 4 && <KyMgnt />} */}
          {tab === 5 && <SysMgnt
            className='z-0'
            changeTab={changeTab}
            departments={departments}
            departmentUsers={departmentUsers}
            createdRooms={createdRooms}
            selectedRoomId={selectedRoomId} />}
          {tab === 6 && <CreateDep />}
          {tab === 7 && <PendingList />}
          {tab === 8 && <BatchAddAcc />}
        </div>
      </div>
    </>
  )
}

export default Settings