import React, { useState, useEffect, createRef } from 'react'
import axios from 'axios';
import mqtt from 'mqtt';

import { Button, Tooltip, message } from 'antd';

import { useAuth } from '../../../../contexts/AuthProvider'
import { ReactComponent as AIRoomIcon } from '../../assets/ai_room.svg';
import { ReactComponent as NormalRoomIcon } from '../../assets/normal_room.svg';

import Message from '../Message';
import InputLine from './InputLine';

import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

const SERVER_URL = process.env.REACT_APP_SERVER_URL;
const MQTT_URL = process.env.REACT_APP_MQTT_URL;
const MQTT_PORT = process.env.REACT_APP_MQTT_PORT;

function formatInputForMarkdown(input) {
  const lines = input.split('\n'); // Split the input by new lines
  const formattedLines = lines.map(line => line.trim() !== '' ? line + ' \\' : '').filter(line => line !== ''); // Add backslash to each line and filter out empty lines
  const submitLines = formattedLines.join('\n'); // Join the lines back together
  return submitLines.substring(0, submitLines.length - 2); // Return the formatted input
}

function convertUrlsToLinks(text) {
  if (!text) return text;
  if (text.includes('[') && text.includes(']') && text.includes('(')) {
    return text;
  }

  // regex for url
  const urlRegex = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/g;

  return text.replace(urlRegex, (url) => {
    // remove punctuation at the end of url
    const cleanUrl = url.replace(/[.,;:!?)"'\]}]+$/, '');

    // remove unpaired parentheses
    let finalUrl = cleanUrl;
    const openParens = (finalUrl.match(/\(/g) || []).length;
    const closeParens = (finalUrl.match(/\)/g) || []).length;
    if (openParens > closeParens) {
      finalUrl = finalUrl.replace(/\([^)]*$/, '');
    }

    return `[${finalUrl}](${finalUrl})`;
  });
}

const Room = ({ isAI, room, messages, setMessages, onClear }) => {
  const target = isAI ? 'ai' : 'normal'
  const { token, userId } = useAuth();
  const [loading, setLoading] = useState(false)
  const [submittedMessage, setSubmittedMessage] = useState('')
  const [initLoading, setInitLoading] = useState(true)
  const [oldestMessageId, setOldestMessageId] = useState(null)
  const [triggerScroll, setTriggerScroll] = useState(true);
  const messagesStartRef = createRef();
  const messagesEndRef = createRef();
  const [mqttConnection, setMqttConnection] = useState(false);
  const [stickyDate, setStickyDate] = useState(null);
  const messagesContainerRef = createRef();

  useEffect(() => {
    setMqttConnection(false)
    setSubmittedMessage('')
    setMessages([])
    setOldestMessageId(null)
    fetchNewMessages(true).then(() => {
      setInitLoading(false);
    });
  }, [room])

  useEffect(() => {
    if (triggerScroll) {
      messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
      setTriggerScroll(false); // Reset scroll trigger
    }
  }, [triggerScroll]); // Depend only on the triggerScroll flag

  // MQTT connect
  useEffect(() => {
    // Establish connection with the MQTT broker
    const mqttClient = mqtt.connect(MQTT_URL, {
      clientId: 'mqttx_' + Math.random().toString(16).substring(2, 10),
      port: MQTT_PORT,
      path: '/',
      connectTimeout: 10000,
      keepAlive: 30000,
      autoReconnect: true,
      reconnectPeriod: 1000,
      cleanStart: false,
    });

    mqttClient.on('error', (error) => {
      console.error('Connection error:', error);
      setMqttConnection(false)
    });

    mqttClient.on('connect', () => {
      if (isAI) {
        // mqttClient.subscribe(`chatroom/${room.id}/ai`);
        mqttClient.subscribe(`chatroom/${room?.id}/ai/response`, (err) => {
          if (err) {
            console.error('[AI] Subscription error:', err);
            setMqttConnection(false)
          } else {
            // console.log('[AI] Subscription successful');
            setMqttConnection(true)
          }
        });
      } else {
        mqttClient.subscribe(`chatroom/${room?.id}/normal`, (err) => {
          if (err) {
            console.error('[normal] Subscription error:', err);
            setMqttConnection(false);
          } else {
            // console.log('[normal] Subscription successful');
            setMqttConnection(true)
          }
        });
      }
    });

    mqttClient.on('message', (topic, payload) => {
      const newMessage = JSON.parse(payload.toString());

      setMessages(prevMessages => [...prevMessages, { ...newMessage }]);

      setTriggerScroll(true);
    });

    // Clean up the connection when the component unmounts
    return () => mqttClient.end();
  }, [room]);

  const fetchOldMessages = async () => {
    const url = `${SERVER_URL}/private/chatrooms/chat/${target}/${room.id}${oldestMessageId ? `?from_id=${oldestMessageId}` : ''}`;
    try {
      const response = await axios.get(url, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      // Combine the current messages with the new response data
      const combinedMessages = [...messages, ...response.data];

      // Remove duplicates
      const uniqueMessages = Array.from(new Map(combinedMessages.map(message => [message.id, message])).values());

      // Sort by 'created_at'
      uniqueMessages.sort((a, b) => new Date(a.created_at) - new Date(b.created_at));

      setMessages(uniqueMessages)

      if (uniqueMessages.length > 0) {
        setOldestMessageId(uniqueMessages[0].id)
      }
    } catch (error) {
      console.log(error)
      // message.error(JSON.stringify(error.response?.data?.detail));
    }
  }

  const fetchNewMessages = async (init = false) => {
    const url = `${SERVER_URL}/private/chatrooms/chat/${target}/${room?.id}`;
    try {
      const response = await axios.get(url, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      if (response.data.length > 0) {

        // Combine the current messages with the new response data
        const combinedMessages = init ? response.data : [...messages, ...response.data];

        // Remove duplicates
        const uniqueMessages = Array.from(new Map(combinedMessages.map(message => [message.id, message])).values());

        // Sort by 'created_at'
        uniqueMessages.sort((a, b) => new Date(a.created_at) - new Date(b.created_at));

        setOldestMessageId(uniqueMessages[0].id)
        setMessages(uniqueMessages)
      }
      setTriggerScroll(true); // Trigger scroll to bottom
    } catch (error) {
      console.log(error)
      // message.error(JSON.stringify(error.response?.data?.detail));
    }
  }

  // TODO: file with too long name needs to be blocked
  const submitFiles = async (blobs) => {
    let ticketId = null;
    try {
      const formData = new FormData();
      blobs.forEach((blob) => {
        formData.append('files', blob);
      });

      const response = await axios.post(SERVER_URL + '/private/chatrooms/chat/blob/' + room.id, formData, {
        headers: {
          'accept': 'application/json',
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'multipart/form-data'
        }
      });
      ticketId = response.data.ticket_id;

      // Call the function to fetch tickets
      return fetchTickets(ticketId);
    } catch (error) {
      message.error(JSON.stringify(error.response?.data?.detail));
      return null; // Return null or handle error as needed
    }
  }

  const fetchTickets = async (ticketId) => {
    try {
      const ticketResponse = await Promise.race([
        axios.get(SERVER_URL + '/public/task/' + ticketId, {
          headers: {
            'accept': 'application/json'
          }
        }),
        new Promise((_, reject) => setTimeout(() => reject(new Error('Request timeout')), 60000))
      ]);

      const ttl = ticketResponse.data.ttl; // Update this path according to your API response

      // TODO drop this if MQTT is workable
      if (ttl === -1) {
        // console.log("TTL is -1, refetching tickets...");
        return fetchTickets(ticketId); // Recursively refetch
      }

      const blobIds = ticketResponse.data.body.data.map(blob => blob.id);
      return blobIds;
    } catch (error) {
      // console.log("Error fetching tickets:", error);
      throw error; // Rethrow the error or handle it appropriately
    }
  }

  const submitAIMessage = async (msg, blobs) => {
    setLoading(true)
    setSubmittedMessage(msg)
    let blobIds = [];
    if (blobs.length > 0) {
      blobIds = await submitFiles(blobs);
    }

    try {
      await axios.post(SERVER_URL + '/private/chatrooms/chat/ai/' + room.id, {
        chat_type: "ai",
        message: formatInputForMarkdown(msg),
        blob_ids: blobIds
      }, {
        headers: {
          'accept': 'application/json',
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        }
      });
    } catch (error) {
      message.error(JSON.stringify(error.response?.data?.detail));
    } finally {
      setLoading(false)
    }
  }

  const submitNormalMessage = async (msg, blobs) => {
    setLoading(true)
    setSubmittedMessage(msg)
    let blobIds = [];
    if (blobs.length > 0) {
      blobIds = await submitFiles(blobs);

      // check if there's a null object in blobIds and pop notification
      if (blobIds.some(blobId => blobId === null)) {
        message.error("部分檔案不支援上傳");
      }

      // clear null object
      blobIds = blobIds.filter(Boolean);
    }

    try {
      await axios.post(SERVER_URL + '/private/chatrooms/chat/normal/' + room.id, {
        chat_type: "normal",
        message: formatInputForMarkdown(msg),
        blob_ids: blobIds
      }, {
        headers: {
          'accept': 'application/json',
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
    } catch (error) {
      console.log(error)
      // message.error(JSON.stringify(error.response?.data?.detail));
    } finally {
      setLoading(false)
    }
  }

  const submitMessage = async (message, blobs) => {
    if (isAI) {
      await submitAIMessage(message, blobs)
    } else {
      await submitNormalMessage(message, blobs)
    }
    await fetchNewMessages();
    setTriggerScroll(true); // Trigger scroll to bottom
  }

  // 添加日期格式化函數
  const formatMessageDate = (date) => {
    const d = new Date(date);
    const year = d.getFullYear();
    const month = String(d.getMonth() + 1).padStart(2, '0');
    const day = String(d.getDate()).padStart(2, '0');
    const weekDay = ['日', '一', '二', '三', '四', '五', '六'][d.getDay()];
    return `${year}-${month}-${day} (${weekDay})`;
  };

  // 添加滾動監聽處理函數
  useEffect(() => {
    const handleScroll = () => {
      if (!messagesContainerRef.current) return;

      const messageGroups = messagesContainerRef.current.querySelectorAll('[data-date]');
      let currentStickyDate = null;

      messageGroups.forEach((group) => {
        const rect = group.getBoundingClientRect();
        if (rect.top <= 100) { // 100px 是頂部預留空間
          currentStickyDate = group.getAttribute('data-date');
        }
      });

      setStickyDate(currentStickyDate);
    };

    const container = messagesContainerRef.current;
    if (container) {
      container.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll);
      }
    };
  }, [messages]);

  // 將消息按日期分組
  const groupMessagesByDate = (messages) => {
    const groups = {};
    messages.forEach((message) => {
      const date = formatMessageDate(message.created_at || message.question?.created_at);
      if (!groups[date]) {
        groups[date] = [];
      }
      groups[date].push(message);
    });
    return groups;
  };

  if (initLoading) {
    return (
      <div className="flex justify-center items-center w-full h-full">
        <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
      </div>
    )
  }

  return (
    <div className="flex flex-col h-full w-full bg-bgLight">
      <div className="flex flex-row justify-between items-center h-8 px-6 py-5 border-b">
        <div className="flex flex-row justify-center items-center gap-2">
          {isAI ? <AIRoomIcon style={{ width: "17px", height: "17px" }} /> : <NormalRoomIcon style={{ width: "17px", height: "17px" }} />}
          {isAI ? "AI " : "一般"}聊天室
        </div>
        {
          mqttConnection ?
            <Tooltip title="連線穩定">
              <div className="flex flex-row gap-1 ml-2 bg-green-600 w-2 h-2 rounded-full" />
            </Tooltip>
            :
            <Tooltip title="重新連線中...點擊以重整">
              <div className="flex flex-row gap-1 ml-2 bg-yellow-600 w-2 h-2 rounded-full animate-ping" onClick={fetchNewMessages} />
            </Tooltip>
        }
        {/* isAI &&
          <div className="flex flex-row justify-between items-center gap-1">
            <Tooltip title="AI 圖片生成">
              <Button className="border-0 bg-bgLight shadow-none min-w-9 min-h-9" icon={<AIPictureIcon style={{ width: "36px", height: "36px" }} />} />
            </Tooltip>
            <Tooltip title="連結">
              <Button className="border-0 bg-bgLight shadow-none min-w-9 min-h-9" icon={<LinkIcon style={{ width: "36px", height: "36px" }} />} />
            </Tooltip>
            <Tooltip title="筆記">
              <Button className="border-0 bg-bgLight shadow-none min-w-9 min-h-9" icon={<MemoryIcon style={{ width: "36px", height: "36px" }} />} />
            </Tooltip>
            <Tooltip title="搜尋">
              <Button disabled className="border-0 bg-bgLight shadow-none min-w-9 min-h-9" icon={<SearchIcon style={{ width: "36px", height: "36px" }} />} />
            </Tooltip>
          </div> */}
      </div>
      <div className="flex flex-col items-center justify-end px-6 overflow-x-hidden" style={{ height: 'calc(100dvh - 40px)', maxHeight: 'calc(100dvh - 40px)' }}>
        <div
          ref={messagesContainerRef}
          className='relative overflow-y-auto overflow-x-hidden py-2 w-full'
          style={{ height: 'calc(100dvh - 40px)', maxHeight: 'calc(100dvh - 40px)' }}
        >
          {stickyDate && (
            <div className="sticky top-0 z-50 bg-white/80 backdrop-blur-sm py-2 px-4 text-center text-sm text-gray-500 shadow-sm">
              {stickyDate}
            </div>
          )}
          <Button ref={messagesStartRef} className="w-full" type="text" onClick={fetchOldMessages}>
            讀取更多訊息
          </Button>
          {Object.entries(groupMessagesByDate(messages)).map(([date, dateMessages]) => (
            <div key={date} data-date={date}>
              <div className="text-center my-4">
                <span className="text-[#989898] text-xs bg-[#E8E8E8] px-2 py-1 rounded-[11px]">
                  {date}
                </span>
              </div>
              {dateMessages.map((message) => {
                if (message.question) {
                  return (
                    <div key={message.id}>
                      <Message
                        roomId={room.id}
                        type={message.question.user_id === userId ? 'self' : 'other'}
                        message={{
                          text: message.question.message,
                          time: message.question.created_at,
                          name: (message.question.user_id !== userId && message.question.user_nickname) ? message.question.user_nickname : null,
                          avatar: (message.question.user_id !== userId && message.question.user_image_url) ? message.question.user_image_url : null
                        }}
                      />
                      {
                        message.question.blobs?.length > 0 &&
                        message.question.blobs.map((blob, index) => (
                          <Message
                            roomId={room.id}
                            key={`${message.id}-question-blob-${index}`}
                            type={message.question.user_id === userId ? 'self' : 'other'}
                            message={{
                              blob: blob.url,
                              blobType: blob.content_type,
                              time: message.question.created_at,
                              name: (message.question.user_id !== userId && message.question.user_nickname) ? message.question.user_nickname : null,
                              avatar: (message.question.user_id !== userId && message.question.user_image_url) ? message.question.user_image_url : null
                            }} />
                        ))
                      }
                      <Message roomId={room.id} type={'other'} message={{
                        name: room.bot_name,
                        text: convertUrlsToLinks(message.message),
                        time: message.created_at,
                        question: message.question.message,
                        avatar: room.photo_url ? room.photo_url : null
                      }} />
                      {
                        message.blobs?.length > 0 &&
                        message.blobs.map((blob, index) => (
                          <Message
                            roomId={room.id}
                            key={`${message.id}-answer-blob-${index}`}
                            type={'other'}
                            message={{
                              blob: blob.url,
                              blobType: blob.content_type,
                              time: message.created_at,
                              avatar: room.photo_url ? room.photo_url : null
                            }} />
                        ))
                      }
                    </div>
                  )
                }
                if (message.blobs?.length > 0) {
                  return (
                    <div key={message.id}>
                      <Message
                        roomId={room.id}
                        type={message.user_id === userId ? 'self' : 'other'}
                        message={{
                          name: message.user_nickname,
                          text: message.message,
                          time: message.created_at,
                          avatar: message.user_image_url ? message.user_image_url : null
                        }} />
                      {
                        message.blobs.map((blob, index) => (
                          <Message
                            roomId={room.id}
                            key={`${message.id}-blob-${index}`}
                            type={message.user_id === userId ? 'self' : 'other'}
                            message={{
                              name: message.user_nickname,
                              blob: blob.url,
                              blobType: blob.content_type,
                              time: message.created_at,
                              avatar: message.user_image_url ? message.user_image_url : null
                            }} />
                        ))
                      }
                    </div>
                  )
                }
                return (
                  <Message
                    roomId={room.id}
                    key={message.id}
                    type={message.user_id === userId ? 'self' : 'other'}
                    message={{
                      name: message.user_nickname,
                      text: message.message,
                      time: message.created_at,
                      avatar: message.user_image_url ? message.user_image_url : null
                    }} />
                )
              })}
            </div>
          ))}
          {isAI && loading && (
            <div>
              <Message
                roomId={room.id}
                type={'self'}
                message={{
                  text: submittedMessage,
                  time: "..."
                }}
              />
              <Message roomId={room.id} type={'skeleton'} ref={messagesEndRef} />
            </div>
          )}
          <div ref={messagesEndRef} />
        </div>
      </div>
      <InputLine isAI={isAI} submit={submitMessage} loading={loading} id={room?.id} />
    </div>
  )
}


export default Room