import React from "react";
import LineSetting from "../chatSettings/LineSetting";
import AgodaSetting from "../chatSettings/AgodaSetting";

/**
 * BoundSetting 組件 - 根據不同的聊天平台顯示相應的設定面板
 * @param {Object} props - 組件屬性
 * @returns {JSX.Element} - 渲染的組件
 */
const BoundSetting = ({ room, token, clientId, tabs, client, updateClientData }) => {
  // 確保 client 對象存在
  if (!client) {
    return (
      <div className="bg-bgLight h-full min-w-72 max-w-72 flex items-center justify-center">
        <div className="text-gray-500">無客戶資料</div>
      </div>
    );
  }

  return (
    <>
      {tabs === 0 ? (
        <div className="bg-bgLight h-full min-w-72 max-w-72">
          <AgodaSetting client={client} />
        </div>
      ) : tabs === 1 ? (
        <div className="bg-bgLight h-full min-w-72 max-w-72 overflow-y-auto">
          <LineSetting 
            room={room} 
            token={token} 
            clientId={clientId} 
            client={client}
            updateClientData={updateClientData}
            showWebhook={false}
          />
        </div>
      ) : 
        ''
      }
    </>
  );
};

export default BoundSetting;