import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { message, Button, Tooltip, Input, Select, Switch, Space } from 'antd'
import { LoadingOutlined, GlobalOutlined, RobotOutlined, FileImageOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import { useSetting } from '../../../../hooks/use-setting';
import { useModel } from '../../../../hooks/use-model';
const { TextArea } = Input;

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

const RuleSetting = ({ room, token }) => {
  const [rule, setRule] = useState("")
  const [loading, setLoading] = useState(false)
  const [selectedModel, setSelectedModel] = useState("")
  const [selectedImageModel, setSelectedImageModel] = useState("")
  const [webSearchEnabled, setWebSearchEnabled] = useState(false)
  const { updateChatroomChatSettings } = useSetting();
  const {
    getChatModels,
    getTextToImageModels,
    getChatroomModelSettings,
    updateChatroomModelSettings,
    updateTextToImageModel,
    models,
    imageModels,
    chatroomModelSettings,
    loading: modelsLoading
  } = useModel();
  const [modelOptions, setModelOptions] = useState([]);
  const [imageModelOptions, setImageModelOptions] = useState([]);
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    if (room && room.id) {
      const initializeData = async () => {
        setLoading(true);
        try {
          const [roomsData, modelsData, imageModelsData, modelSettingsData] = await Promise.allSettled([
            fetchRoomsData(),
            fetchModelsData(),
            fetchImageModelsData(),
            fetchModelSettingsData()
          ]);

          let modelValue = "";
          let imageModelValue = "";

          if (modelSettingsData.status === 'fulfilled') {
            if (modelSettingsData.value?.body?.model) {
              modelValue = modelSettingsData.value.body.model;
            }
            if (modelSettingsData.value?.body?.text_to_image_model) {
              imageModelValue = modelSettingsData.value.body.text_to_image_model;
            }
          } else if (roomsData.status === 'fulfilled' &&
            roomsData.value?.aiModel) {
            modelValue = roomsData.value.aiModel;
          } else if (modelsData.status === 'fulfilled' &&
            Object.keys(modelsData.value).length > 0) {
            modelValue = Object.keys(modelsData.value)[0];
          }

          if (modelValue) {
            setSelectedModel(modelValue);
          }

          if (imageModelValue) {
            setSelectedImageModel(imageModelValue);
          } else if (imageModelsData.status === 'fulfilled' &&
            Object.keys(imageModelsData.value).length > 0) {
            // 默認選第一個
            setSelectedImageModel(Object.keys(imageModelsData.value)[0]);
          }

          setIsInitialized(true);
        } catch (error) {
          console.error('初始化數據失敗:', error);
          setIsInitialized(true);
        } finally {
          setLoading(false);
        }
      };

      initializeData();
    }
  }, [room]);

  const fetchRoomsData = async () => {
    try {
      const settingsResponse = await axios.get(`${SERVER_URL}/private/chatrooms/chat/settings/${room.id}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      if (settingsResponse.data && settingsResponse.data.body) {
        setWebSearchEnabled(settingsResponse.data.body.web_search || false);
      }
      const roomDetailsResponse = await axios.get(`${SERVER_URL}/private/chatrooms/${room.id}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      if (roomDetailsResponse.data) {
        setRule(roomDetailsResponse.data.instructions || "");
      }
      return settingsResponse.data;
    } catch (error) {
      console.error('獲取聊天室資料失敗:', error);
      message.error('獲取聊天室設置失敗: ' + (error.response?.data?.detail || error.message));
      return null;
    }
  };

  const fetchModelsData = async () => {
    try {
      const modelData = await getChatModels();
      if (modelData && Object.keys(modelData).length > 0) {
        const options = Object.entries(modelData).map(([key, value]) => ({
          value: key,
          label: value
        }));
        setModelOptions(options);
      }
      return modelData;
    } catch (error) {
      setModelOptions([]);
      return null;
    }
  };

  const fetchImageModelsData = async () => {
    try {
      const modelData = await getTextToImageModels();
      if (modelData && Object.keys(modelData).length > 0) {
        const options = Object.entries(modelData).map(([key, value]) => ({
          value: key,
          label: value
        }));
        setImageModelOptions(options);
      }
      return modelData;
    } catch (error) {
      setImageModelOptions([]);
      return null;
    }
  };

  const fetchModelSettingsData = async () => {
    try {
      const settings = await getChatroomModelSettings(room.id);
      return settings;
    } catch (error) {
      return null;
    }
  };

  const reloadData = async () => {
    setLoading(true);
    try {
      await Promise.all([
        fetchRoomsData(),
        fetchModelsData(),
        fetchImageModelsData(),
        fetchModelSettingsData()
      ]);
    } catch (error) {
      console.error('重新加載數據失敗:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateSettings = async (checked = webSearchEnabled) => {
    try {
      const settings = {
        web_search: checked,
        as_graph: false,
        rag_mode: "vector_based",
        bypass_prompt_optimization: false
      };

      await updateChatroomChatSettings(room.id, settings);
      message.success(`網路搜尋功能已${checked ? '啟用' : '停用'}`);
    } catch (error) {
    }
  };

  const onChangeRule = (e) => {
    setRule(e.target.value)
  }

  const handleChangeRule = async () => {
    setLoading(true)

    try {
      await axios.patch(SERVER_URL + '/private/chatrooms/setting/instructions/' + room.id, {
        text: rule
      }, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
      message.success('聊天室規則已更新');
    } catch (error) {
      message.error(JSON.stringify(error.response?.data?.detail));
    } finally {
      setLoading(false)
    }
  }

  const handleModelChange = async (value) => {
    if (!value) return;

    try {
      setSelectedModel(value);

      await updateChatroomModelSettings(room.id, value);

      // 模型更新後重新獲取最新設定，確保文字轉圖片模型設定是最新的
      const updatedSettings = await getChatroomModelSettings(room.id);
      if (updatedSettings?.body?.text_to_image_model) {
        setSelectedImageModel(updatedSettings.body.text_to_image_model);
      }

      const modelName = models[value] || value;
      message.success(`已將聊天室模型更新為: ${modelName}`);
    } catch (error) {
      if (chatroomModelSettings?.body?.model) {
        setSelectedModel(chatroomModelSettings.body.model);
      } else {
        reloadData();
      }
    }
  };

  const handleImageModelChange = async (value) => {
    if (!value) return;

    try {
      setSelectedImageModel(value);

      await updateTextToImageModel(room.id, value);

      const modelName = imageModels[value] || value;
      message.success(`已將文字轉圖片模型更新為: ${modelName}`);
    } catch (error) {
      if (chatroomModelSettings?.body?.text_to_image_model) {
        setSelectedImageModel(chatroomModelSettings.body.text_to_image_model);
      } else {
        reloadData();
      }
    }
  };

  if (loading || modelsLoading || !isInitialized) {
    return (
      <div className="flex justify-center items-center w-full h-full">
        <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
      </div>
    )
  }

  const hasModelOptions = modelOptions && modelOptions.length > 0;
  const hasImageModelOptions = imageModelOptions && imageModelOptions.length > 0;

  const displayOptions = hasModelOptions ? modelOptions :
    (selectedModel ? [{ value: selectedModel, label: selectedModel }] : []);
  
  const displayImageOptions = hasImageModelOptions ? imageModelOptions :
    (selectedImageModel ? [{ value: selectedImageModel, label: selectedImageModel }] : []);

  return (
    <div className="flex flex-col justify-start items-start w-full px-4 py-6 gap-4">
      <div className="text-lg text-textDark font-bold w-full">
        聊天室規則設置
      </div>

      <div className="w-full space-y-4">
        {/* AI 模型選擇 */}
        <div className="flex items-center gap-4">
          <RobotOutlined className="text-xl" />
          <div className="flex-1">
            <div className="text-sm font-medium mb-2">AI 模型選擇</div>
            <Select
              value={selectedModel}
              onChange={handleModelChange}
              className="w-full"
              options={displayOptions}
              loading={modelsLoading}
              placeholder="請選擇AI模型"
              notFoundContent={modelsLoading ? <Spin size="small" /> : "沒有可用的模型"}
              disabled={!hasModelOptions && !selectedModel}
            />
            {!hasModelOptions && (
              <div className="text-xs text-red-500 mt-1">
                無法獲取模型列表，請稍後再試或刷新頁面
                <Button
                  type="link"
                  size="small"
                  onClick={reloadData}
                  loading={loading}
                >
                  重新加載
                </Button>
              </div>
            )}
          </div>
        </div>

        {/* 文字轉圖片 模型選擇 */}
        <div className="flex items-center gap-4">
          <FileImageOutlined className="text-xl" />
          <div className="flex-1">
            <div className="text-sm font-medium mb-2">文字轉圖片 模型選擇</div>
            <Select
              value={selectedImageModel}
              onChange={handleImageModelChange}
              className="w-full"
              options={displayImageOptions}
              loading={modelsLoading}
              placeholder="請選擇AI模型"
              notFoundContent={modelsLoading ? <Spin size="small" /> : "沒有可用的模型"}
              disabled={!hasImageModelOptions && !selectedImageModel}
            />
            {!hasImageModelOptions && (
              <div className="text-xs text-red-500 mt-1">
                無法獲取模型列表，請稍後再試或刷新頁面
                <Button
                  type="link"
                  size="small"
                  onClick={reloadData}
                  loading={loading}
                >
                  重新加載
                </Button>
              </div>
            )}
          </div>
        </div>

        {/* 網路搜尋功能 */}
        <div className="flex items-center gap-4">
          <GlobalOutlined className="text-xl" />
          <div className="flex-1">
            <div className="text-sm font-medium mb-2">網路搜尋功能</div>
            <Space>
              <Switch
                checked={webSearchEnabled}
                onChange={(checked) => {
                  setWebSearchEnabled(checked);
                  handleUpdateSettings(checked);
                }}
              />
              <span className="text-sm text-gray-600">
                {webSearchEnabled ? '已啟用網路搜尋' : '已停用網路搜尋'}
              </span>
            </Space>
          </div>
        </div>
      </div>

      <div className="text-base font-medium mt-4">
        聊天室規則內容
      </div>
      <Tooltip title="AI助手在介紹自己和迎接使用者時應該遵循哪些指引？AI助手該如何確定介紹的語氣和風格？例如正式、隨意、友好或專業AI助手助手的首選回應風格是什麼？應該是知識性、幽默、簡潔還是冗長的？AI助手是否應該使用表情符號或根據使用者的偏好調整語言？AI助手可以包括如何引入和更改話題？AI是否應該堅持特定主題，還是可以討論各種主題？">
        <TextArea showCount maxLength={3000} value={rule} onChange={onChangeRule} placeholder="請輸入您的聊天室規則" className="min-h-60 mb-8" disabled={loading} />
      </Tooltip>
      <div className="flex flex-row justify-end w-full">
        <Button type="primary" onClick={handleChangeRule} loading={loading}>
          儲存
        </Button>
      </div>
    </div>
  )
}

export default RuleSetting
