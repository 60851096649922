import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Input, message } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { useAuth } from '../../../contexts/AuthProvider';
import { useUser } from '../../../contexts/UserProvider';
// Domain layer - Password validation rules
const isValidPassword = (password) => {
  const regex = /^[A-Za-z0-9\s!@#$%^&*()_+\-=[\]{};:'",.<>/?\\|`~]*$/;
  return regex.test(password);
};

const PasswordWindow = ({ roomId, onClose }) => {
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const { token } = useAuth();
  const { user } = useUser();
  const navigate = useNavigate();

  const SERVER_URL = process.env.REACT_APP_SERVER_URL;
  
  // Use case layer - Handle password input with validation
  const handlechatRoomPassword = (e) => {
    const newPassword = e.target.value;
    if (newPassword === '' || isValidPassword(newPassword)) {
      setPassword(newPassword);
      setError('');
    } else {
      setError('密碼只能包含英文字母、數字和符號');
    }
  };

  // Use case layer - Handle form submission
  const handleSubmit = async () => {
    if (error) {
      message.error(error);
      return;
    }

    
    if (user?.role_id === 4) {
      navigate(`/chat/${roomId}`);
      message.success('成功加入新私人聊天室');
      return;
    }
    
    if (!password.trim()) {
      message.error('請輸入密碼');
      return;
    }
    try {
      await axios.post(`${SERVER_URL}/private/chatrooms/join/${roomId}`,
        { text: password }, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
      });
      navigate(`/chat/${roomId}`);
      message.success('成功加入新私人聊天室');
    } catch (error) {
      if (error.response?.data?.detail === 'Invalid passphrase') {
        message.error('密碼錯誤');
        return;
      }
      message.error('發生錯誤，請稍後再試');
    }
  };

  // UI layer
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-20 z-50">
      <div className='w-[340px] h-[204px] flex flex-col bg-[#F2F2F2] justify-between rounded-[7px] overflow-hidden'>
        <div className='w-full flex flex-col items-start p-2'>
          <button className='flex justify-end w-full'
            onClick={onClose}><CloseOutlined /></button>
          <div className='w-full p-4'>
            <p className='flex justify-start w-full mb-2 font-semibold text-[17px]'>
              請輸入房間密碼
            </p>
            <Input 
              className='w-full rounded-[5px]'
              value={password}
              onChange={handlechatRoomPassword}
              status={error ? 'error' : ''}
              placeholder="僅限英文字母、數字和符號"
            />
            {error && <p className='text-red-500 text-sm mt-1'>{error}</p>}
          </div>
        </div>
        <div className='w-full bg-[#ADDEAC] flex justify-center'>
          <button className='w-full h-10' onClick={handleSubmit}>確定</button>
        </div>
      </div>
    </div>
  );
};

export default PasswordWindow;