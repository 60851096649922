import { AddLineChannelNote } from '../../application/useCases/AddLineChannelNote';
import { FetchLineChannelNote } from '../../application/useCases/FetchLineChannelNote';
import { LineChannelRepository } from '../../infrastructure/repositories/LineChannelRepository';
import { CacheRepository } from '../../infrastructure/repositories/CacheRepository';

export const LineChannelFacade = {
  // ... 其他現有的方法 ...

  /**
   * Add a note to a line channel client
   * @param {string} chatroomId - The chatroom ID
   * @param {string} clientId - The client ID
   * @param {string} noteName - The note name
   * @param {string} noteContent - The note content
   * @returns {Promise<Object>} The response data
   * @throws {Error} If the operation fails
   */
  addNote: async (chatroomId, clientId, noteName, noteContent) => {
    try {
      return await AddLineChannelNote.addNote(
        LineChannelRepository,
        chatroomId,
        clientId,
        noteName,
        noteContent
      );
    } catch (error) {
      console.error('Facade Error in addNote:', error);
      // 統一的錯誤處理
      if (error.message.includes('系統忙碌中')) {
        throw new Error('系統忙碌中，請稍後再試');
      } else if (error.message.includes('找不到指定')) {
        throw new Error('找不到指定的聊天室或客戶，請確認後重試');
      } else if (error.message.includes('請求參數無效')) {
        throw new Error('請求參數無效，請確認輸入內容');
      } else if (error.message.includes('備註內容不能超過')) {
        throw new Error('備註內容不能超過300字');
      }
      throw new Error('系統發生錯誤，請稍後再試');
    }
  },
  fetchLineClients: async (chatroomId) => {
    try {
      return await FetchLineChannelNote.fetchLineClients(
        LineChannelRepository,
        chatroomId
      );
    } catch (error) {
      console.error('Facade Error in fetchLineClients:', error);
      if (error.message.includes('找不到指定')) {
        throw new Error('找不到指定的聊天室，請確認後重試');
      }
      throw new Error('系統發生錯誤，請稍後再試');
    }
  },

  /**
   * Delete a note from a line channel client
   * @param {string} chatroomId - The chatroom ID
   * @param {string} clientId - The client ID
   * @param {string} noteId - The note ID
   * @returns {Promise<Object>} The response data
   * @throws {Error} If the operation fails
   */
  deleteNote: async (chatroomId, clientId, noteId) => {
    try {
      return await AddLineChannelNote.deleteNote(
        LineChannelRepository,
        chatroomId,
        clientId,
        noteId
      );
    } catch (error) {
      console.error('Facade Error in deleteNote:', error);
      // 統一的錯誤處理
      if (error.message.includes('系統忙碌中')) {
        throw new Error('系統忙碌中，請稍後再試');
      } else if (error.message.includes('找不到指定的備註')) {
        throw new Error('找不到指定的備註，請確認後重試');
      } else if (error.message.includes('請求參數無效')) {
        throw new Error('請求參數無效，請確認輸入內容');
      }
      throw new Error('系統發生錯誤，請稍後再試');
    }
  },

  /**
   * Add a tag to a line channel client
   * @param {string} chatroomId - The chatroom ID
   * @param {string} clientId - The client ID
   * @param {string} tagName - The tag name
   * @returns {Promise<Object>} The response data
   * @throws {Error} If the operation fails
   */
  addTag: async (chatroomId, clientId, tagName) => {
    try {
      return await LineChannelRepository.addTag(chatroomId, clientId, tagName);
    } catch (error) {
      console.error('Facade Error in addTag:', error);
      if (error.message.includes('系統忙碌中')) {
        throw new Error('系統忙碌中，請稍後再試');
      } else if (error.message.includes('找不到指定')) {
        throw new Error('找不到指定的聊天室或客戶，請確認後重試');
      } else if (error.message.includes('請求參數無效')) {
        throw new Error('請求參數無效，請確認輸入內容');
      }
      throw new Error('系統發生錯誤，請稍後再試');
    }
  },

  /**
   * Delete a tag from a line channel client
   * @param {string} chatroomId - The chatroom ID
   * @param {string} clientId - The client ID
   * @param {string} tagId - The tag ID
   * @returns {Promise<Object>} The response data
   * @throws {Error} If the operation fails
   */
  deleteTag: async (chatroomId, clientId, tagId) => {
    try {
      return await LineChannelRepository.deleteTag(chatroomId, clientId, tagId);
    } catch (error) {
      console.error('Facade Error in deleteTag:', error);
      if (error.message.includes('系統忙碌中')) {
        throw new Error('系統忙碌中，請稍後再試');
      } else if (error.message.includes('找不到指定的標籤')) {
        throw new Error('找不到指定的標籤，請確認後重試');
      } else if (error.message.includes('請求參數無效')) {
        throw new Error('請求參數無效，請確認輸入內容');
      }
      throw new Error('系統發生錯誤，請稍後再試');
    }
  },
  fetchTagGroup: async (chatroomId) => {
    try {
      return await LineChannelRepository.fetchTagGroup(chatroomId);
    } catch (error) {
      console.error('Facade Error in fetchTagGroup:', error);
      if (error.message.includes('找不到指定')) {
        throw new Error('找不到指定的聊天室，請確認後重試');
      }
      throw error;
    }
  },

  async setLastSeenMessageCache(client) {
    try {
      if (!client?.last_two_messages?.[0]?.id) {
        throw new Error('No last message found');
      }
      console.log('12345', client);
      
      return await CacheRepository.setLastSeenMessageCache(
        client.chatroom_id,
        client.external_channel_id,
        client.external_id,
        client.last_two_messages[0].id
      );
    } catch (error) {
      console.error('Error in LineChannelFacade:', error);
      throw error;
    }
  }
}; 