
import { createContext, useContext, useEffect, useState, useCallback } from 'react';
import { useAuth } from './AuthProvider';
import axios from 'axios';
const UserContext = createContext();
export function useUser() {
	return useContext(UserContext);
}
export const UserProvider = ({ children }) => {
	const { userId, token } = useAuth();
	const [user, setUser] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState(null);
	const SERVER_URL = process.env.REACT_APP_SERVER_URL;
	const fetchUserData = useCallback(async (isMounted = true) => {
		if (!token) {
			if (isMounted) {
				setUser(null);
				setIsLoading(false);
				setError(null);
			}
			return;
		}
		if (!SERVER_URL) {
			if (isMounted) {
				setError('服務器配置缺失');
				setIsLoading(false);
			}
			return;
		}
		try {
			setIsLoading(true);
			const response = await axios.get(`${SERVER_URL}/private/user/me`, {
				headers: {
					'Accept': 'application/json',
					'Authorization': `Bearer ${token}`
				},
				timeout: 10000
			});
			if (!isMounted) return;
			if (response?.data) {
				setUser(response.data);
				setError(null);
			} else {
				setError('無效的響應數據');
			}
		} catch (error) {
			if (!isMounted) return;
			let errorMessage = '發生未知錯誤';
			if (axios.isAxiosError(error)) {
				if (error.code === 'ECONNABORTED') {
					errorMessage = '請求超時，請稍後重試';
				} else if (error.response) {
					errorMessage = error.response.data?.detail || '伺服器響應錯誤';
				} else if (error.request) {
					errorMessage = '無法連接到伺服器';
				}
			}
			setError(errorMessage);
			setUser(null);
		} finally {
			if (isMounted) {
				setIsLoading(false);
			}
		}
	}, [token, SERVER_URL]);
	useEffect(() => {
		let isMounted = true;
		fetchUserData(isMounted);
		return () => {
			isMounted = false;
		};
	}, [userId, fetchUserData]);
	const value = {
		user,
		isLoading,
		error,
		refetchUser: () => fetchUserData(true)
	};
	return (
		<UserContext.Provider value={value}>
			{children}
		</UserContext.Provider>
	);
};





