import * as modelUseCase from '../useCases/ModelUseCase';

/**
 * Get available chat models
 * @returns {Promise<Object>} Object containing model IDs as keys and display names as values
 */
export const getChatModels = async () => {
  try {
    return await modelUseCase.getChatModels();
  } catch (error) {
    throw error;
  }
};

/**
 * Get chatroom model settings
 * @param {string} chatroomId - The ID of the chatroom
 * @returns {Promise<Object>} Chatroom model settings
 */
export const getChatroomModelSettings = async (chatroomId) => {
  try {
    return await modelUseCase.getChatroomModelSettings(chatroomId);
  } catch (error) {
    throw error;
  }
};

/**
 * Get available text to image models
 * @returns {Promise<Object>} Object containing model IDs as keys and display names as values
 */
export const getTextToImageModels = async () => {
  try {
    return await modelUseCase.getTextToImageModels();
  } catch (error) {
    throw error;
  }
};

/**
 * Update chatroom model settings
 * @param {string} chatroomId - The ID of the chatroom
 * @param {string} modelId - The model ID
 * @param {Object} modelArgs - The model arguments (optional)
 * @param {string} textToImageModelId - The text to image model ID (optional)
 * @returns {Promise<Object>} Updated chatroom model settings
 */
export const updateChatroomModelSettings = async (chatroomId, modelId, modelArgs = {}, textToImageModelId = null) => {
  try {
    return await modelUseCase.updateChatroomModelSettings(chatroomId, modelId, modelArgs, textToImageModelId);
  } catch (error) {
    throw error;
  }
}; 