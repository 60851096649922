
import React, { useEffect, useState } from 'react'
import { message } from 'antd';
import axios from 'axios';

import { useUser } from '../../../contexts/UserProvider';
import { useAuth } from '../../../contexts/AuthProvider';

import MgntAvatar from './MgntAvatar';

const ComMgnt = () => {
  const { user } = useUser();
  const [companyAvatar, setCompanyAvatar] = useState();
  const [companyName, setCompanyName] = useState();
  const { token } = useAuth();

  const SERVER_URL = process.env.REACT_APP_SERVER_URL;
  //get company detail
  const fetchCoData = async () => {
    if (!token) return;
    try {
      const response = await axios.get(`${SERVER_URL}/private/company/`, {
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });
      const data = await response.data;
      setCompanyAvatar(data.photo_url);
      setCompanyName(data.name);
    } catch (error) {
      message.error(JSON.stringify(error.response?.data?.detail));
    }
  };

  useEffect(() => {
    fetchCoData();
  }, []);

  return (
    <div className="flex flex-col w-full bg-white p-">
      <div className="px-4 font-bold text-xl mb-1 mt-6">
        編輯公司
      </div>
      <div className='w-[540px]'>
        <MgntAvatar
          photoUrl={companyAvatar}
          uploadUrl={`${SERVER_URL}/private/company/image`}
          title=''
          name={companyName}
          refresh={fetchCoData}
        />
      </div>
      <div className='flex flex-col p-3'>
        <div className='flex p-1 items-center'>
          <p className='w-[270px] h-[32px] p-1 bg-bgDark mr-[7px] text-[17px] border-b-[1px] border-[#D9D9D9]'>
            公司編號</p>
          <p className='w-[240px]  text-nowrap'>
            {user.company_id}
          </p>
        </div>
        <div className='flex p-1'>
          <p className='w-[270px] h-[32px] p-1 bg-bgDark mr-[7px] text-[17px] border-b-[1px] border-[#D9D9D9]'>
            公司名稱</p>
          <p className='w-[240px] rounded-[5px]'>
            {user.company_name}
          </p>
        </div>
      </div>
      <div className='flex p-5'>
        <button
          className='text-[14px] bg-[#DEACAC] p-2 rounded-[3px] w-[58px] h-[32px] flex items-center justify-center'
          onClick={() => window.location.reload()}
        >
          返回
        </button>
      </div>
    </div>
  )
}

export default ComMgnt;