import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { request } from '../utils';

const initialState = {
    template: [],
};

// fetch template
export const fetchTemplate = createAsyncThunk(
    'memory/fetchTemplate',
    async ({ room_id }, { rejectWithValue }) => {
        try {
            const res = await request.get(
                `/private/chatrooms/vector_store/template/${room_id}/all`
            );
            return res;
        } catch (error) {
            console.error('Error fetching template:', error);
            return rejectWithValue(error.response?.data || 'Fetch template failed');
        }
    }
);

// add template
export const addTemplate = createAsyncThunk(
    'memory/addTemplate',
    async ({ room_id, template }, { rejectWithValue }) => {
        try {
            const res = await request.post(
                `/private/chatrooms/vector_store/template/${room_id}`,
                template
            );
            return res;
        } catch (error) {
            console.error('Error adding template:', error);
            return rejectWithValue(error.response?.data || 'Add template failed');
        }
    }
);

// update template
export const updateTemplate = createAsyncThunk(
    'memory/updateTemplate',
    async ({ room_id, template, template_id }, { rejectWithValue }) => {
        try {
            const res = await request.put(
                `/private/chatrooms/vector_store/template/${room_id}/sample/${template_id}`,
                template
            );
            return res;
        } catch (error) {
            console.error('Error updating template:', error);
            return rejectWithValue(error.response?.data || 'Update template failed');
        }
    }
);

// add record
export const addRecord = createAsyncThunk(
    'memory/addRecord',
    async ({ room_id, record, template_id }, { rejectWithValue }) => {
        try {
            const res = await request.post(
                `/private/chatrooms/vector_store/template/${room_id}/append/${template_id}`,
                record
            );
            return res;
        } catch (error) {
            console.error('Error adding record:', error);
            return rejectWithValue(error.response?.data || 'Add record failed');
        }
    }
);

// update record
export const updateRecord = createAsyncThunk(
    'memory/updateRecord',
    async ({ room_id, record, record_id }, { rejectWithValue }) => {
        try {
            const res = await request.put(
                `/private/chatrooms/vector_store/template/${room_id}/sample/${record_id}`,
                record
            );
            return res;
        } catch (error) {
            console.error('Error updating record:', error);
            return rejectWithValue(error.response?.data || 'Update record failed');
        }
    }
);

// memory service
const memoryService = createSlice({
    name: 'memory',
    initialState,
    reducers: {
        // Set the template in memory
        setTemplate(state, action) {
            const newTemplate = action.payload;
            if (Array.isArray(newTemplate)) {
                state.template = newTemplate;
            } else if (typeof newTemplate === 'object' && newTemplate !== null) {
                const index = state.template.findIndex(
                    (item) => item.id === newTemplate.id
                );
                if (index !== -1) {
                    state.template[index] = newTemplate;
                } else {
                    state.template.push(newTemplate);
                }
            } else {
                console.error(
                    'Invalid payload format: expected an array or an object.'
                );
            }
        },
        // clear the template in memory
        clearTemplate(state) {
            state.template = [];
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchTemplate.fulfilled, (state, action) => {
                // update template
                state.template = action.payload;
            })
            .addCase(addTemplate.fulfilled, (state, action) => {
                // when success then add template
                state.template.push(action.payload);
            })
            .addCase(updateTemplate.fulfilled, (state, action) => {
                // update template
                const index = state.template.findIndex(
                    (item) => item.id === action.payload.id
                );
                if (index !== -1) {
                    state.template[index] = action.payload;
                }
            })
            .addCase(fetchTemplate.rejected, (state, action) => {
                console.error('Fetch template failed:', action.payload);
            })
            .addCase(addTemplate.rejected, (state, action) => {
                console.error('Add template failed:', action.payload);
            })
            .addCase(updateTemplate.rejected, (state, action) => {
                console.error('Update template failed:', action.payload);
            })
            .addCase(addRecord.rejected, (state, action) => {
                console.error('Add record failed:', action.payload);
            })
            .addCase(updateRecord.rejected, (state, action) => {
                console.error('Update record failed:', action.payload);
            });
    },
});

export const { setTemplate, clearTemplate } = memoryService.actions;
export default memoryService.reducer;
