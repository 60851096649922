import { useState } from 'react';
import { message } from 'antd';
import * as modelFacade from '../application/facades/ModelFacade';

/**
 * Custom hook for handling chat models
 * @returns {Object} Hook methods and state
 */
export const useModel = () => {
  const [loading, setLoading] = useState(false);
  const [models, setModels] = useState({});
  const [imageModels, setImageModels] = useState({});
  const [error, setError] = useState(null);
  const [chatroomModelSettings, setChatroomModelSettings] = useState(null);

  /**
   * Get available chat models
   */
  const getChatModels = async () => {
    try {
      setLoading(true);
      setError(null);
      const response = await modelFacade.getChatModels();
      setModels(response);
      return response;
    } catch (error) {
      setError(error);
      message.error(error.message || '獲取聊天模型失敗');
      throw error;
    } finally {
      setLoading(false);
    }
  };

  /**
   * Get available text to image models
   */
  const getTextToImageModels = async () => {
    try {
      setLoading(true);
      setError(null);
      const response = await modelFacade.getTextToImageModels();
      setImageModels(response);
      return response;
    } catch (error) {
      setError(error);
      message.error(error.message || '獲取文字轉圖片模型失敗');
      throw error;
    } finally {
      setLoading(false);
    }
  };

  /**
   * Get chatroom model settings
   * @param {string} chatroomId - The ID of the chatroom
   */
  const getChatroomModelSettings = async (chatroomId) => {
    try {
      setLoading(true);
      setError(null);
      const response = await modelFacade.getChatroomModelSettings(chatroomId);
      setChatroomModelSettings(response);
      return response;
    } catch (error) {
      setError(error);
      message.error(error.message || '獲取聊天室模型設定失敗');
      throw error;
    } finally {
      setLoading(false);
    }
  };

  /**
   * Update chatroom model settings
   * @param {string} chatroomId - The ID of the chatroom
   * @param {string} modelId - The model ID
   * @param {Object} modelArgs - The model arguments (optional)
   * @param {string} textToImageModelId - The text to image model ID (optional)
   */
  const updateChatroomModelSettings = async (chatroomId, modelId, modelArgs = {}, textToImageModelId = null) => {
    try {
      setLoading(true);
      setError(null);
      const response = await modelFacade.updateChatroomModelSettings(
        chatroomId, 
        modelId, 
        modelArgs,
        textToImageModelId
      );
      setChatroomModelSettings(response);
      return response;
    } catch (error) {
      setError(error);
      message.error(error.message || '更新聊天室模型設定失敗');
      throw error;
    } finally {
      setLoading(false);
    }
  };

  /**
   * Update only the text to image model setting
   * @param {string} chatroomId - The ID of the chatroom
   * @param {string} textToImageModelId - The text to image model ID
   */
  const updateTextToImageModel = async (chatroomId, textToImageModelId) => {
    try {
      setLoading(true);
      setError(null);
      
      // 獲取當前模型設定
      let currentModel = '';
      let currentModelArgs = {};
      
      if (chatroomModelSettings?.body) {
        currentModel = chatroomModelSettings.body.model || '';
        currentModelArgs = chatroomModelSettings.body.model_args || {};
      }
      
      if (!currentModel) {
        throw new Error('無法獲取當前模型設定');
      }
      
      // 更新設定
      const response = await modelFacade.updateChatroomModelSettings(
        chatroomId, 
        currentModel, 
        currentModelArgs,
        textToImageModelId
      );
      
      setChatroomModelSettings(response);
      return response;
    } catch (error) {
      setError(error);
      message.error(error.message || '更新文字轉圖片模型設定失敗');
      throw error;
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    models,
    imageModels,
    error,
    chatroomModelSettings,
    getChatModels,
    getTextToImageModels,
    getChatroomModelSettings,
    updateChatroomModelSettings,
    updateTextToImageModel
  };
}; 