
import React, { useState, useEffect } from "react";
import { Spin, Segmented, Tooltip } from "antd";
import {
  LoadingOutlined,
  EditOutlined,
  PlusOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import {
  fetchHotels,
  fetchCustomTags,
  DeleteASRTagFromProfile,
  fetchProfile,
} from "./hooks/voice.api";
import { HotelModal, TagModal, KeywordModal } from "./VoiceModals";

const VoiceConfig = ({ room, token, profile }) => {
  const [tab, setTab] = useState("目標飯店");
  const [asrKeywords, setAsrKeywords] = useState([]);
  const [hotels, setHotels] = useState([]);
  const [tags, setTags] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showHotelModal, setShowHotelModal] = useState(false);
  const [selectedHotel, setSelectedHotel] = useState(null);
  const [showTagModal, setShowTagModal] = useState(false);
  const [create, setCreate] = useState(false);
  const [showKeywordModal, setShowKeywordModal] = useState(false);

  useEffect(() => {
    profile && refresh();
  }, [room, profile]);

  const refresh = async () => {
    setLoading(true);
    setSelectedHotel(null);
    setCreate(false);
    fetchCustomTags(room.id, token, profile.body.tag_group_id).then((data) => {
      data &&
        setTags(data.tags.map((tag) => ({ label: tag.name, value: tag.id })));
      fetchHotels(room.id, token).then((data) => {
        data &&
          setHotels(
            data.map((hotel) => ({ label: hotel.body.name, value: hotel.id }))
          );
        fetchProfile(room.id, token).then((data) => {
          data && setAsrKeywords(data.body.asr_keywords);
          setLoading(false);
        });
      });
    });
  };

  return (
    <>
      <HotelModal
        roomId={room.id}
        token={token}
        open={showHotelModal}
        close={setShowHotelModal}
        hotel={selectedHotel}
        create={create}
        refresh={refresh}
      />
      <TagModal
        roomId={room.id}
        token={token}
        open={showTagModal}
        close={setShowTagModal}
        refresh={refresh}
        tagGroupId={profile.body.tag_group_id}
      />
      <KeywordModal
        roomId={room.id}
        token={token}
        open={showKeywordModal}
        close={setShowKeywordModal}
        refresh={refresh}
      />
      <div className="bg-bgLight h-full min-w-72 max-w-72">
        <Segmented
          block
          className="my-4 mx-4 bg-bgDark"
          options={["目標飯店", "標籤", "常用詞彙"]}
          defaultValue="目標飯店"
          onChange={(value) => {
            setTab(value);
          }}
        />
        <div className="flex flex-col w-full">
          <div className="flex flex-row bg-bgDark h-8 items-center w-64 gap-2 px-2 mx-auto">
            <div className="flex w-56 text-sm">項目名稱</div>
            <PlusOutlined
              className="text-textDark hover:primary cursor-pointer hover:text-primary"
              onClick={() => {
                setCreate(true);
                if (tab === "目標飯店") {
                  setShowHotelModal(true);
                } else if (tab === "標籤") {
                  setShowTagModal(true);
                } else if (tab === "常用詞彙") {
                  setShowKeywordModal(true);
                }
              }}
            />
          </div>
          {loading ? (
            <div className="flex justify-center items-center w-full h-72">
              <Spin
                indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />}
              />
            </div>
          ) : (
            {
              目標飯店: hotels.map((hotel) => (
                <div
                  key={hotel.value}
                  className="flex flex-row bg-bgLight h-8 items-center w-64 gap-2 px-2 mx-auto border-b py-4"
                >
                  <Tooltip title={hotel.label.length > 15 && hotel.label}>
                    <div className="flex w-56 text-sm truncate">
                      {hotel.label}
                    </div>
                  </Tooltip>
                  <EditOutlined
                    className="text-textDark hover:primary cursor-pointer hover:text-primary"
                    onClick={() => {
                      setCreate(false);
                      setShowHotelModal(true);
                      setSelectedHotel({
                        name: hotel.label,
                        id: hotel.value,
                      });
                    }}
                  />
                </div>
              )),
              標籤: tags.map((tag) => (
                <div
                  key={tag.value}
                  className="flex flex-row bg-bgLight h-8 items-center w-64 gap-2 px-2 mx-auto border-b py-4"
                >
                  <Tooltip title={tag.label.length > 15 && tag.label}>
                    <div className="flex w-56 text-sm truncate">
                      {tag.label}
                    </div>
                  </Tooltip>
                </div>
              )),
              常用詞彙: asrKeywords.map((keyword) => (
                <div
                  key={keyword}
                  className="flex flex-row bg-bgLight h-8 items-center w-64 gap-2 px-2 mx-auto border-b py-4"
                >
                  <Tooltip title={keyword.length > 15 && keyword}>
                    <div className="flex w-56 text-sm truncate">{keyword}</div>
                  </Tooltip>
                  <CloseOutlined
                    className="text-textDark hover:primary cursor-pointer hover:text-red-500"
                    onClick={async () => {
                      await DeleteASRTagFromProfile(room.id, token, keyword);
                      refresh();
                    }}
                  />
                </div>
              )),
            }[tab]
          )}
        </div>
      </div>
    </>
  );
};

export default VoiceConfig;
