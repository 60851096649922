import { AgodaUseCases } from '../../application/useCases/AgodaUseCases';
import { AgodaRepository } from '../../infrastructure/repositories/AgodaRepository';

export const AgodaFacade = {
  // Get complete chat flow
  getAgodaChatFlow: async (chatroomId, token) => {
    try {
      return await AgodaUseCases.getAgodaChatFlow(AgodaRepository, chatroomId, token);
    } catch (error) {
      // console.error('Facade Error in getAgodaChatFlow:', error);
      // Return user-friendly error messages
      if (error.message.includes('無法獲取')) {
        throw new Error(error.message);
      }
      throw new Error('系統發生錯誤，請稍後再試');
    }
  },

  // Get conversation data
  getConversationData: async (conversationId) => {
    try {
      return await AgodaUseCases.getConversationData(AgodaRepository, conversationId);
    } catch (error) {
      console.error('Facade Error in getConversationData:', error);
      if (error.message.includes('找不到')) {
        throw new Error(error.message);
      }
      throw new Error('無法取得對話資料，請稍後再試');
    }
  }
}; 