import { useState, useCallback } from 'react';
import { AgodaFacade } from '../application/facades/AgodaFacade';
import { useAuth } from '../contexts/AuthProvider';

export const useAgoda = () => {
  const { token } = useAuth();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [agodaData, setAgodaData] = useState(null);
  const [conversationData, setConversationData] = useState(null);
  const [processedConversations, setProcessedConversations] = useState(null);

  // 初始化 Agoda 聊天室
  const initializeAgodaChat = useCallback(async (chatroomId) => {
    try {
      setLoading(true);
      setError(null);
      const data = await AgodaFacade.getAgodaChatFlow(chatroomId, token);
      setAgodaData(data);
      return data;
    } catch (error) {
      setError(error.message);
      throw error;
    } finally {
      setLoading(false);
    }
  }, [token]);

  // 獲取特定對話內容
  const fetchConversationData = useCallback(async (conversationId) => {
    try {
      setLoading(true);
      setError(null);
      const data = await AgodaFacade.getConversationData(conversationId);
      setConversationData(data);
      return data;
    } catch (error) {
      setError(error.message);
      throw error;
    } finally {
      setLoading(false);
    }
  }, []);

  // 處理所有來源的對話資料
  const processAllConversations = useCallback(async (sources) => {
    try {
      setLoading(true);
      setError(null);

      // 獲取所有對話 ID
      const conversationPromises = sources.map(source => 
        AgodaFacade.getConversationId(source.sourceId)
      );
      
      const conversationIds = await Promise.all(conversationPromises);

      // 獲取每個對話的預訂和訊息資料
      const processedData = await Promise.all(
        conversationIds.map(async (conversationId, index) => {
          const [bookings, messages] = await Promise.all([
            AgodaFacade.getBookingInfo(conversationId),
            AgodaFacade.getConversationMessages(conversationId)
          ]);

          return {
            sourceInfo: sources[index],
            conversationId,
            bookings,
            messages,
            timestamp: new Date().toISOString()
          };
        })
      );

      setProcessedConversations(processedData);
      console.log('已處理的對話資料:', processedData);
      return processedData;
    } catch (error) {
      setError(error.message);
      throw error;
    } finally {
      setLoading(false);
    }
  }, []);

  // 清除錯誤
  const clearError = useCallback(() => {
    setError(null);
  }, []);

  // 重置狀態
  const reset = useCallback(() => {
    setAgodaData(null);
    setConversationData(null);
    setProcessedConversations(null);
    setError(null);
    setLoading(false);
  }, []);

  return {
    loading,
    error,
    agodaData,
    conversationData,
    processedConversations,
    initializeAgodaChat,
    fetchConversationData,
    processAllConversations,
    clearError,
    reset
  };
}; 