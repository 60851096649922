import { request } from '../../utils';

export class CacheApi {
  static async setCacheStr(key, value) {
    try {
      const response = await request.post(
        '/private/cache/str',
        null,
        {
          params: {
            key,
            value
          }
        }
      );
      return response.data;
    } catch (error) {
      console.error('API Error in setCacheStr:', error);
      if (error.response) {
        const status = error.response.status;
        const detail = error.response.data?.detail;
        
        if (status === 400) {
          throw new Error(detail || '無效的請求參數');
        } else if (status === 500) {
          throw new Error('伺服器錯誤');
        }
      }
      throw new Error('網路連線異常，請稍後再試');
    }
  }
} 