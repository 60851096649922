import React from 'react'
import { useUser } from '../../../contexts/UserProvider'
const SettingMenu = (props) => {
  const { user } = useUser();
  // 判斷是否為系統管理相關頁面
  const isSystemManagement = props.currentTab === 5 || props.currentTab === 0 || props.currentTab === 1 || props.currentTab === 2 || props.currentTab === 3 || props.currentTab === 6 || props.currentTab === 8;
  return (
    <div className="flex flex-col w-64 bg-bgMedium p-4 gap-2">
      <button
        className={`p-2 rounded-[5px] w-full flex ${isSystemManagement ? 'bg-[#7AB679]' : 'bg-none'}`}
        onClick={() => props.changeTab(5)}
      >
        總後台管理
      </button>
      {
        user?.role_id === 4 && (
          <button
            className={`p-2 rounded-[5px] w-full flex ${props.currentTab === 7 ? 'bg-[#7AB679]' : 'bg-none'}`}
            onClick={() => props.changeTab(7)}
          >
            審核管理
          </button>
        )
      }
    </div>
  )
}

export default SettingMenu;