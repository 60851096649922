import request from '../../utils/request';

export const SearchApi = {
  /**
   * 搜尋聊天室中的客戶
   * @param {string} chatroomId - 聊天室ID
   * @param {string} searchText - 搜尋文字
   * @returns {Promise<Array<string>>} - 返回符合條件的客戶external_id列表
   */
  searchClients: async (chatroomId, searchText) => {
    try {
      const response = await request.get(
        `/private/chatrooms/chat/${chatroomId}/search/clients`,
        {
          params: {
            channel_type: 'line',
            query: searchText // 修改參數名稱為 q
          }
        }
      );
      return response;
    } catch (error) {
      console.error('SearchApi.searchClients Error:', error);
      throw error;
    }
  }
}; 