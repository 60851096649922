import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table, Button, message, Spin, Modal } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useAuth } from '../../../contexts/AuthProvider';
const PendingList = () => {
    const [pendingUsers, setPendingUsers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { token } = useAuth();
    const SERVER_URL = process.env.REACT_APP_SERVER_URL;

    const columns = [
        {
            title: '暱稱',
            dataIndex: 'username',
            key: 'username',
        },
        {
            title: '電子信箱',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: '部門',
            dataIndex: 'department_name',
            key: 'department_name',
        },
        {
            title: '分配房間數',
            dataIndex: 'room_count',
            key: 'room_count',
        },
        {
            title: '申請日期',
            dataIndex: 'created_at',
            key: 'created_at',
            render: (text) => new Date(text).toLocaleString('zh-TW'),
        }
    ];

    const fetchPendingUsers = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`${SERVER_URL}/private/company/users/applicants`, {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });
            setPendingUsers(response.data);
        } catch (error) {
            message.error('獲取待審核名單失敗');
        } finally {
            setLoading(false);
        }
    };

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleModalOk = async () => {
        try {
            await handleApprove(selectedRowKeys);
            setIsModalOpen(false);
        } catch (error) {
            message.error('審核失敗');
        }
    };

    const handleModalCancel = () => {
        setIsModalOpen(false);
    };

    const handleApprove = async (userIds) => {
        try {
            const params = new URLSearchParams();
            userIds.forEach(id => {
                params.append('applicant_ids', id.toString());
            });
            
            await axios.post(
                `${SERVER_URL}/private/company/users/verify?${params.toString()}`,
                {},
                {
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                }
            );
            message.success('已通過審核');
            setSelectedRowKeys([]);
            fetchPendingUsers();
        } catch (error) {
            console.error('審核失敗:', error);
            message.error('審核失敗');
        }
    };

    const onSelectChange = (newSelectedRowKeys) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const hasSelected = selectedRowKeys.length > 0;

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        fetchPendingUsers();
    }, []);

    if (loading) {
        return (
            <div className="flex items-center justify-center w-full h-full">
                <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
            </div>
        );
    }

    return (
        <div className="p-6">
            <div className="flex justify-between mb-4">
                <span className="text-gray-600">
                    {hasSelected ? `已選擇 ${selectedRowKeys.length} 個項目` : ''}
                </span>
                <div className="flex gap-2">
                    <Button
                        type="primary"
                        className="bg-[#7AB679] hover:bg-[#7AB679]/80"
                        onClick={showModal}
                        disabled={!hasSelected}
                    >
                        通過
                    </Button>
                </div>
            </div>
            <Table
                rowSelection={rowSelection}
                size="middle"
                className="[&_.ant-table-thead>tr>th]:bg-[#E0F0E0]"
                columns={columns}
                dataSource={pendingUsers}
                rowKey="id"
                pagination={{
                    defaultPageSize: 10,
                    showSizeChanger: true,
                    showTotal: (total) => `共 ${total} 筆資料`,
                }}
            />
            <Modal
                title="審核通過確認"
                open={isModalOpen}
                onOk={handleModalOk}
                onCancel={handleModalCancel}
                width={400}
                okText="確認"
                cancelText="取消"
                className="[&_.ant-modal-content]:p-0 [&_.ant-modal-header]:p-3 [&_.ant-modal-body]:p-6 [&_.ant-modal-footer]:p-3"
            >
                <div className="text-center">
                    <p>確定要通過所選用戶的審核嗎？</p>
                </div>
            </Modal>
        </div>
    );
};

export default PendingList; 