
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Button, Modal, Input, Select, Spin, message } from 'antd';
import { LoadingOutlined, PlusCircleOutlined } from '@ant-design/icons';

import { useAuth } from '../../../../contexts/AuthProvider';

import { ReactComponent as PublicIcon } from '../../assets/privacy_public.svg';
import { ReactComponent as PrivateIcon } from '../../assets/privacy_private.svg';

const SERVER_URL = process.env.REACT_APP_SERVER_URL;
const LAST_PAGE_INDEX = 3;

// const MODEL_LIST = [
//   { value: "Open AI", label: "Open AI" },
// ];

const Pagination = ({ prevPage, nextPage, prevBtnDisabled, nextBtnDisabled, nextBtnTerminated }) => (
  <div className="flex flex-row justify-end items-center gap-2 mt-4 px-4">
    <Button type="primary" onClick={prevPage} disabled={prevBtnDisabled}>上一頁</Button>
    {/* <div>{page + 1} / 5</div> */}
    <Button type="primary" onClick={nextPage} disabled={nextBtnDisabled}>
      {nextBtnTerminated ? <div>創建</div> : <div>下一頁</div>}
    </Button>
  </div>
);

const Privacy = ({ privacy, setPrivacy }) => (
  <div className="flex flex-row justify-between items-center w-80">
    <div onClick={() => setPrivacy(false)} className={`w-38 h-38 cursor-pointer flex flex-col items-center p-6 bg-white rounded-lg hover:bg-bgLight border ${privacy === false ? "border-primary text-primary font-bold" : "border-borderDark"}`}>
      <PublicIcon className="w-24 h-24 mb-2" />
      <div>公開</div>
    </div>
    <div onClick={() => setPrivacy(true)} className={`w-38 h-38 cursor-pointer flex flex-col items-center p-6 bg-white rounded-lg hover:bg-bgLight border ${privacy === true ? "border-primary text-primary font-bold" : "border-borderDark"}`}>
      <PrivateIcon className="w-24 h-24 mb-2" />
      <div>私人</div>
    </div>
  </div>
);

const Name = ({ privacy, name, changeName, password, changePassword }) => (
  <div className="items-start">
    <div className="text-textDark">聊天室名稱</div>
    <Input className="w-80" value={name} onChange={e => changeName(e.target.value)} />
    {privacy && (
      <>
        <div className="text-textDark mt-4">密碼 (至少四位數)</div>
        <Input.Password className="w-80" value={password} onChange={e => changePassword(e.target.value)} />
      </>
    )}
  </div>
);

const Group = ({ token, groupList, group, changeGroup, fetchGroups }) => {
  const filterOption = (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());
  const [openNewGroup, setOpenNewGroup] = useState(false);
  const [newGroupName, setNewGroupName] = useState("");
  const [loading, setLoading] = useState(false);

  const handleCreateGroup = async () => {
    setLoading(true)
    try {
      await axios.post(SERVER_URL + "/private/company/departments/group?group_name=" + newGroupName, {}, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
      setNewGroupName("")
      setOpenNewGroup(false)
    } catch (error) {
      message.error(JSON.stringify(error.response?.data?.detail));
    }
    setLoading(false)
    fetchGroups()
  }

  return (
    <>
      <Modal
        width={400}
        open={openNewGroup}
        onCancel={() => setOpenNewGroup(false)}
        footer={null}
      >
        <div className="items-center flex flex-col mt-4">
          <div className="text-textDark self-start ml-4">分類名稱</div>
          <Input maxLength={30} disabled={loading} className="w-80" value={newGroupName} onChange={e => setNewGroupName(e.target.value)} />
          <Button type="primary" className="mt-4 self-end mr-4" onClick={handleCreateGroup} loading={loading}>新增分類</Button>
        </div>
      </Modal>
      <div className="flex flex-col items-start">
        <div className="text-textDark">聊天室分類</div>
        <Select
          showSearch
          placeholder="選擇聊天室分類"
          value={group.name}
          optionFilterProp="children"
          onChange={changeGroup}
          filterOption={filterOption}
          options={groupList.map(group => ({ value: group.name, label: group.name }))}
          className="w-80 self-center"
        />
        <Button type="text" className="mt-2 self-end" icon={<PlusCircleOutlined />} onClick={() => setOpenNewGroup(true)}>新增分類</Button>
      </div>
    </>
  );
};

const Customization = ({ botName, changeBotName }) => {
  return (
    <div className="items-start">
      <div className="text-textDark">AI 暱稱 (選填)</div>
      <Input
        className="w-80"
        value={botName}
        onChange={e => changeBotName(e.target.value)}
        maxLength={20}
      />
    </div>
  );

}

const CreateChat = ({ isModalOpen, closeModal }) => {
  const { token } = useAuth();
  const navigate = useNavigate();

  const [page, setPage] = useState(0);
  const [prevBtnDisabled, setPrevBtnDisabled] = useState(true);
  const [nextBtnDisabled, setNextBtnDisabled] = useState(true);
  const [nextBtnTerminated, setNextBtnTerminated] = useState(false);
  const [privacy, setPrivacy] = useState(null);
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [group, setGroup] = useState("");
  const [model, setModel] = useState("");
  const [textChunkSize, setTextChunkSize] = useState("");
  const [textChunkOverlap, setTextChunkOverlap] = useState("");
  const [groupList, setGroupList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [botName, setBotName] = useState("");

  useEffect(() => {
    resetState();
    fetchGroupList();
  }, [isModalOpen]);

  useEffect(() => {
    checkNextValidity();
  }, [page, privacy, name, password, group, model, textChunkSize, textChunkOverlap]);

  const fetchGroupList = async () => {
    try {
      const response = await axios.get(SERVER_URL + "/private/company/departments/group/my", {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      setGroupList(response.data);
    } catch (error) {
    }
  }

  const resetState = () => {
    setPage(0);
    setLoading(false);
    setPrevBtnDisabled(true);
    setNextBtnDisabled(true);
    setNextBtnTerminated(false);
    setPrivacy(null);
    setName("");
    setPassword("");
    setGroup("");
    setModel("");
    setTextChunkSize("");
    setTextChunkOverlap("");
    setBotName("");
  };

  const checkNextValidity = () => {
    let isDisabled = true;

    switch (page) {
      case 0:
        isDisabled = privacy === null;
        break;
      case 1:
        isDisabled = name === "" || (privacy && password.length < 4);
        break;
      case 2:
        isDisabled = group === "";
        break;
      case 3:
        isDisabled = false;
        break;
      default:
        isDisabled = true;
    }

    setNextBtnDisabled(isDisabled);
    setNextBtnTerminated(page === LAST_PAGE_INDEX && !isDisabled);
  };

  const changeName = value => setName(value);
  const changePassword = value => setPassword(value);
  const changeGroup = value => setGroup(value);
  // const changeModel = value => setModel(value);
  // const changeTextChunkSize = value => setTextChunkSize(value);
  // const changeTextChunkOverlap = value => setTextChunkOverlap(value);
  const changeBotName = value => setBotName(value);

  const nextPage = () => {
    if (nextBtnTerminated) {
      handleSubmit();
      return;
    }
    setPage(current => current + 1);
    setPrevBtnDisabled(false);
  };

  const prevPage = () => {
    setPage(current => current - 1);
    setNextBtnTerminated(false);
    if (page === 1) setPrevBtnDisabled(true);
  };

  const handleSubmit = async () => {
    setLoading(true);
    let roomId = "";

    try {
      const response = await axios.post(`${SERVER_URL}/private/chatrooms/create`, {
        "name": name,
        "bot_name": botName ? botName : "AI 助理",
        "group": group,
        "is_public": !privacy,
        "passphrase": password ? password : "0000"
      }, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      roomId = response.data.id;
    } catch (error) {
      message.error(JSON.stringify(error.response?.data?.detail));
      closeModal();
      setLoading(false);
      return; // Exit if creation fails
    }

    // try {
    //   await axios.post(`${SERVER_URL}/private/chatrooms/join/${roomId}`);
    // } catch (error) {
    //   setLoading(false);
    //   return; // Exit if creation fails
    // }

    closeModal();
    setLoading(false);

    navigate(`/chat/${roomId}`);
    // reload
    window.location.reload();
  };

  return (
    <Modal
      width={400}
      footer={!loading && <Pagination {...{ prevPage, nextPage, page, prevBtnDisabled, nextBtnDisabled, nextBtnTerminated }} />}
      open={isModalOpen}
      onCancel={closeModal}
    >
      <div className="flex flex-col items-center mt-4">
        <div className="text-xl font-bold">創建聊天室</div>
        <div className="text-sm text-textLight">創建屬於你的聊天室</div>
        <div className="flex flex-col items-center justify-center my-4 min-h-20">
          {
            loading ?
              <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
              :
              <div>
                {page === 0 && <Privacy privacy={privacy} setPrivacy={setPrivacy} />}
                {page === 1 && <Name {...{ privacy, name, changeName, password, changePassword }} />}
                {page === 2 && <Group {...{ token, groupList, group, changeGroup }} fetchGroups={fetchGroupList} />}
                {/* {page === 3 && <Model {...{ model, changeModel }} />} */}
                {/* {page === 4 && <Config {...{ textChunkSize, changeTextChunkSize, textChunkOverlap, changeTextChunkOverlap }} />} */}
                {page === 3 && <Customization {...{ botName, changeBotName }} />}
              </div>
          }
        </div>
      </div>
    </Modal>
  );
};

export default CreateChat;