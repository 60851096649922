/**
 * Line Channel Note Use Cases
 */
export const FetchLineChannelNote = {
  /**
   * Add a note to a line channel client
   * @param {Object} repository - The repository instance
   * @param {string} chatroomId - The chatroom ID
   * @param {string} clientId - The client ID
   * @param {string} noteName - The note name
   * @param {string} noteContent - The note content
   * @returns {Promise<Object>} The response data
   * @throws {Error} If the operation fails
   */
  fetchLineClients: async (repository, chatroomId) => {
    const response = await repository.fetchLineClients(chatroomId);
    return response;
  },
  fetchTagGroup: async (repository, chatroomId) => {
    const response = await repository.fetchTagGroup(chatroomId);
    return response;
  }
}; 