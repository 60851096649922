import React, { useEffect, useState } from 'react';
import { RightCircleFilled, CloseOutlined, UserOutlined } from '@ant-design/icons';
import { Table, Avatar, message } from 'antd';

import { useAuth } from '../../../contexts/AuthProvider';

import ConfirmWindow from './ConfirmWindow';
import axios from 'axios';

const RmMemList = ({ roomId, onClose, changeOwner }) => {
  const { userId, token } = useAuth();
  const [membersData, setMembersData] = useState();
  const [selectedMember, setSelectedMember] = useState(null);
  const [isPopup, setIsPopup] = useState(false);

  const SERVER_URL = process.env.REACT_APP_SERVER_URL;

  const columns = [
    {
      title: '',
      dataIndex: 'photo_url',
      key: 'photo_url',
      render: text => text ? <Avatar src={text} alt="photo" size={40} /> : <Avatar icon={<UserOutlined />} size={40} />,
    },
    {
      title: '名稱',
      dataIndex: 'nickname',
      key: 'nickname',
    },
    {
      title: '部門',
      dataIndex: 'department_name',
      key: 'department_name',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      render: text => (
        <span className='flex w-full justify-between'>
          {text} <RightCircleFilled className='text-[#ADDEAC] text-[22px]' />
        </span>
      ),
    },
  ];

  const fetchRmMemList = async () => {
    try {
      const memberResponse = await axios.get(`${SERVER_URL}/private/chatrooms/${roomId}/members/detail`, {
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });
      let memData = memberResponse.data;
      memData = memData.filter(member => member.role_id >= 0);
      setMembersData(memData);
    } catch (error) {
      message.error(JSON.stringify(error.response?.data?.detail));
    }
  };

  useEffect(() => {
    fetchRmMemList();
  }, [roomId, userId]);

  const confirmChangeOwner = async () => {
    try {
      await axios.patch(`${SERVER_URL}/private/chatrooms/setting/ownership/${roomId}?user_id=${selectedMember.id}`,
        null, {
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });
      // const data = response.data;
      // if (response.status === 200) {
      message.success('管理員變更成功');
      changeOwner(selectedMember.nickname, selectedMember.id); // Update parent component state
      // fetchRmMemList(); 
      // } else {
      //   message.error('Failed to change owner');
      // }
    } catch (error) {
      message.error(JSON.stringify(error.response?.data?.detail));
    }
  };
  const handleRowClick = (record) => {
    setSelectedMember(record);
    setIsPopup(true);
  };

  useEffect(() => {
  }, [selectedMember]);

  const handlePopupClose = () => {
    setIsPopup(false);
  };


  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-30 z-30">
      <div className='fixed flex flex-col border-[1px] border-gray w-[1100px] h-[710px] p-6 rounded-[9px] bg-[#F7F7F7] z-20 '>
        <h3 className='w-full flex justify-between font-semibold p-2 mb-[10px]'>
          聊天室成員列表
          <button onClick={onClose}>
            <CloseOutlined />
          </button>
        </h3>

        <Table
          columns={columns}
          dataSource={membersData}
          rowKey="id"
          onRow={(record) => {
            return {
              onClick: () => handleRowClick(record),
              style: {
                cursor: record.role_id < 0 ? 'not-allowed' : 'pointer',
              }
            };
          }}
          rowClassName={(record) => (record.role_id < 0 ? 'disabled-row' : '')}
        />
        {isPopup && <ConfirmWindow
          title='轉移房間所有人'
          context='確認是否轉移房間所有人?'
          handlePopupClose={handlePopupClose}
          confirmAction={confirmChangeOwner} />}
      </div>
    </div>
  )
}

export default RmMemList