
import { useEffect, useState } from 'react';
import axios from 'axios';
import { Spin, Input, message } from 'antd';
import { useNavigate } from 'react-router-dom';

import { useAuth } from '../../contexts/AuthProvider';
import { useUser } from '../../contexts/UserProvider';

import RoomInfo from './components/RoomInfo';
import PasswordWindow from './components/PasswordWindow';
import UserAvatar from '../settings/UserAvatar'

import { LoadingOutlined } from '@ant-design/icons';

const Explore = () => {
    const [activeButton, setActiveButton] = useState('全部');
    const [chatRoomInfo, setChatRoomInfo] = useState(null);
    const [filteredRooms, setFilteredRooms] = useState([]);
    const [categories, setCategories] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const { user, isLoading } = useUser(); // use useUser
    const { token } = useAuth();
    const [isSystemPage, setSystemPage] = useState(false);
    const [isPopup, setIsPopup] = useState(false);
    const [roomId, setRoomId] = useState(null);
    const [activeCategory, setActiveCategory] = useState('all');
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const SERVER_URL = process.env.REACT_APP_SERVER_URL;
    useEffect(() => {
        setSystemPage(false);
    }, []);

    const handleButtonClick = (buttonName) => {
        setActiveButton(buttonName);
        filterRooms(chatRoomInfo, buttonName, activeCategory, searchTerm);
    };
    const handleCategoryClick = (category) => {
        setFilteredRooms(chatRoomInfo?.filter(room => room?.group === category && room?.name?.includes(searchTerm)));
        setActiveCategory(category);
        filterRooms(chatRoomInfo, activeButton, category, searchTerm);
    };


    const handleSearchChange = (e) => {
        const searchValue = e.target.value;
        setSearchTerm(searchValue);
        filterRooms(chatRoomInfo, activeButton, activeCategory, searchValue);
    };
    const filterRooms = (rooms, buttonName, category, term) => {
        let filtered = rooms;
        const lowerCaseTerm = term.toLowerCase();
        if (buttonName === '公開') {
            filtered = rooms?.filter(room => room?.is_public);
        } else if (buttonName === '私人') {
            filtered = rooms?.filter(room => !room?.is_public);
        }

        // 按類別篩選
        if (category && category !== 'all') {
            filtered = filtered?.filter(room => room?.group === category);
        }

        // 按搜尋詞篩選
        if (term) {
            filtered = filtered?.filter(room => room?.name?.toLowerCase().includes(lowerCaseTerm) || room?.group?.toLowerCase().includes(lowerCaseTerm));
        }

        setFilteredRooms(filtered);
    };

    //get department chatroom list
    const fetchChatrooms = async () => {
        if (!token || !user) return;
        setLoading(true);

        try {
            let allChatRooms = [];
            if (user.role_id === 4) {
                // 獲取部門列表
                const departmentsResponse = await axios.get(`${SERVER_URL}/private/company/departments/`, {
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                });

                // 並行獲取所有部門的聊天室
                const departments = departmentsResponse.data;
                const roomPromises = departments.map(dept => {
                    return axios.get(`${SERVER_URL}/private/chatrooms/list/department/${dept.id}`, {
                        headers: {
                            'Accept': 'application/json',
                            'Authorization': `Bearer ${token}`
                        }
                    })
                        .then(response => {
                            return response.data;
                        })
                        .catch(error => {
                            // console.error(`Error fetching rooms for department ${dept.id}:`, error);
                            return [];
                        });
                });

                // 等待所有請求完成
                const roomsResponses = await Promise.all(roomPromises);
                allChatRooms = roomsResponses.flat();
            } else {
                // 獲取個人聊天室
                const response = await axios.get(`${SERVER_URL}/private/chatrooms/list/department/my`, {
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                });
                allChatRooms = response.data;
            }
            // 處理聊天室資料
            const uniqueRooms = Array.from(new Map(allChatRooms.map(room => [room.id, room])).values());
            setChatRoomInfo(uniqueRooms);
            setFilteredRooms(uniqueRooms);
            const uniqueCategories = [...new Set(uniqueRooms.map(room => room.group))];
            setCategories(uniqueCategories);

        } catch (error) {
            message.error(JSON.stringify(error.response?.data?.detail));
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (!isLoading && user) {
            fetchChatrooms();
        }
    }, [user, isLoading]);

    const onClose = () => {
        setIsPopup(false);
    };

    const joinRoom = async (roomId, isPublic) => {
        const hasJoined = user.joined_rooms.includes(roomId);
        if (hasJoined) {
            navigate(`/chat/${roomId}`);
            return;
        }
        if (!isPublic) {
            setIsPopup(true);
            setRoomId(roomId);
            return
        }
        try {
            const response = await axios.post(`${SERVER_URL}/private/chatrooms/join/${roomId}`, {
                text: ''
            }, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
            });
            navigate(`/chat/${roomId}`);
            message.success('成功加入新公開聊天室');
            const data = response.data
            if (response.status === 200) {
                navigate(`/chat/${roomId}`);
            } else if (response.status === 400) {
                if (data.detail === 'You are already a member of this chatroom') {
                    navigate(`/chat/${roomId}`);
                } else {
                    throw new Error(data);
                }
            } else {
                throw new Error(data);
            }
        }
        catch (error) {
            message.error(JSON.stringify(error.response?.data?.detail));
        }
    };

    return (
        <div className='flex w-[100%] h-[100dvh] overflow-hidden z-0' >
            {/* 分類 */}
            <div className='flex flex-col min-w-72 w-72 max-w-72 bg-[#F0F0F0] border-[1px] border-gray py-4 px-5 h-full'>
                <h3 className='text-textDark font-semibold text-[23px] p-[10px]'>
                    探索
                </h3>
                <div className='flex flex-col p-[7px] w-full overflow-x-hidden overflow-y-auto h-full'>
                    <button
                        className={`p-1 w-full flex justify-start ml-1 mt-1 hover:bg-primaryMedium rounded ${activeCategory === 'all' ? 'bg-primaryMedium font-semibold' : ''}`}
                        onClick={() => handleCategoryClick('all')}
                    >
                        所有分類
                    </button>
                    {loading ? <div className='flex items-center justify-center w-full h-full'>
                        <Spin
                            indicator={
                                <LoadingOutlined
                                    style={{
                                        fontSize: 48,
                                    }}
                                    spin
                                />
                            }
                        />
                    </div> : <div>
                        {categories && categories.map((category, i) => (
                            <button key={i} className={`p-1 w-full flex justify-start ml-1 mt-1 hover:bg-primaryMedium rounded ${activeCategory === category ? 'bg-primaryMedium font-semibold' : ''}`}
                                onClick={() => handleCategoryClick(category)}>
                                <p className='w-full break-words text-left'>{category}</p>
                            </button>
                        ))}
                    </div>}
                </div>
            </div>
            {/* main area */}
            <div className='flex flex-col w-full bg-bgLight overflow-hidden'>
                <div className='flex justify-end border-[1px] border-gray p-2'>
                    <div className='flex justify-end w-[100%] relative'>
                        <Input
                            placeholder='搜尋'
                            value={searchTerm}
                            onChange={handleSearchChange}
                            className='w-1/4 border-[#D9D9D9] border-[1px] rounded-[8px] mr-[10px]' />
                        <UserAvatar
                            isSystemPage={isSystemPage}
                        />
                    </div>
                </div>
                {/* chat room list */}
                <div className='flex flex-col p-4 h-full'>
                    <div className='p-[15px] w-[238px] flex justify-between'>
                        <button className={`w-[66px] ${activeButton === '全部' ? 'bg-[#ADDEAC]' : ''} border-[#E6E6E6] border-[1px] rounded-[12px]`}
                            onClick={() => handleButtonClick('全部')}>
                            全部</button>
                        <button className={`w-[66px] ${activeButton === '公開' ? 'bg-[#ADDEAC]' : ''} border-[#E6E6E6] border-[1px] rounded-[12px]`}
                            onClick={() => handleButtonClick('公開')}>
                            公開</button>
                        <button className={`w-[66px] ${activeButton === '私人' ? 'bg-[#ADDEAC]' : ''} border-[#E6E6E6] border-[1px] rounded-[12px]`}
                            onClick={() => handleButtonClick('私人')}>
                            私人</button>
                    </div>
                    {
                        loading ? <div className='flex items-center justify-center w-full h-full'>
                            <Spin
                                indicator={
                                    <LoadingOutlined
                                        style={{
                                            fontSize: 48,
                                        }}
                                        spin
                                    />
                                }
                            />
                        </div> :
                            (
                                <div className='flex flex-wrap w-full overflow-y-auto overflow-x-hidden pb-16 gap-2'>
                                    {filteredRooms && filteredRooms.map(room =>
                                        <div key={room.id} className='w-[250px] p-[10px] cursor-pointer'
                                            onClick={() => { joinRoom(room.id, room.is_public) }}>
                                            <RoomInfo
                                                name={room.name}
                                                is_public={room.is_public}
                                                category={room.group}
                                                onlineMembers={room.num_of_members} />
                                        </div>
                                    )}
                                </div>
                            )
                    }
                    {isPopup && <PasswordWindow className='z-10'
                        onClose={onClose}
                        roomId={roomId}
                    />}
                </div>
            </div>
        </div>
    )
}

export default Explore