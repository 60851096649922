import React, { useState, useRef, useEffect } from 'react';
import { Input, Select } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';

const SearchBar = ({
  defaultSearchRange = '全部',
  onSearch,
  disabled = false,
  placeholder = "依照範圍搜尋",
  options = [
    { value: '全部', label: '全部' },
    { value: '名稱', label: '名稱' },
    { value: '別名', label: '別名' },
    { value: '訊息', label: '訊息' },
    { value: '備註', label: '備註' },
  ],
  className = '',
  style = {},
  value = '',
  onChange,
  ...props
}) => {
  const [searchRange, setSearchRange] = useState(defaultSearchRange);
  const [internalValue, setInternalValue] = useState(value);
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const containerRef = useRef(null);

  // 當 defaultSearchRange 改變時更新內部狀態
  useEffect(() => {
    setSearchRange(defaultSearchRange);
  }, [defaultSearchRange]);

  useEffect(() => {
    setInternalValue(value);
  }, [value]);

  // Handle search logic
  const handleSearch = (text, range) => {
    if (onSearch) {
      onSearch(text, range);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target) &&
          containerRef.current && !containerRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleSelectChange = (value) => {
    setSearchRange(value);
    handleSearch(internalValue, value);
  };

  const handleInputChange = (e) => {
    const newValue = e.target.value;
    setInternalValue(newValue);
    if (onChange) {
      onChange(e);
    }
    // 當輸入為空時立即觸發搜尋
    if (!newValue) {
      handleSearch(newValue, searchRange);
    }
  };

  const handlePressEnter = (e) => {
    if (e.key === "Enter" && !e.nativeEvent.isComposing) { 
      handleSearch(internalValue, searchRange);
    }
  };

  return (
    <div className={`relative ${className}`} ref={containerRef} style={style}>
      {/* Search Input */}
      <div className="flex items-center w-full  rounded-lg border border-gray-200">
        <Input
          {...props}
          className="flex-grow !border-none !outline-none !bg-white active:bg-white hover:bg-white"
          style={{ boxShadow: 'none' }}
          prefix={<SearchOutlined className="text-gray-400" />}
          onChange={handleInputChange}
          onPressEnter={handlePressEnter}
          value={internalValue}
          allowClear
          disabled={disabled}
          placeholder="搜尋 ↵送出"
        />
        <Select
          value={searchRange}
          onChange={handleSelectChange}
          className="!border-none bg-white"
          popupClassName="hidden"
          variant="borderless"
          suffixIcon={<span className="text-gray-400">▼</span>}
          onClick={() => disabled ? null : setIsOpen(!isOpen)}
          open={false}
          disabled={disabled}
        />
      </div>

      {/* Dropdown Menu */}
      {isOpen && (
        <div 
          ref={dropdownRef}
          className="absolute top-full left-0 right-0 mt-1 flex flex-col w-full bg-white rounded-lg shadow-lg border border-gray-200 pt-4 z-50"
        >
          <div className="text-gray-400 text-sm mb-2 px-4">{placeholder}</div>
          <div className="flex flex-col gap-2">
            {options.map((option) => (
              <div
                key={option.value}
                className={`flex items-center px-8 py-2 rounded-lg cursor-pointer hover:bg-gray-50 gap-2 ${
                  searchRange === option.value ? 'text-gray-900 bg-gray-50' : 'text-gray-400'
                }`}
                onClick={() => {
                  handleSelectChange(option.value);
                  setIsOpen(false);
                }}
              >
                <span className="text-black">{option.label}</span>
                <span className="text-gray-400">搜尋</span>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

SearchBar.propTypes = {
  defaultSearchRange: PropTypes.string,
  onSearch: PropTypes.func,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ),
  className: PropTypes.string,
  style: PropTypes.object,
  value: PropTypes.string,
  onChange: PropTypes.func,
};

export default SearchBar; 