import React, { useState, useEffect } from 'react'
import classnames from 'classnames'
import { Button, Tabs, Spin } from 'antd'
import { LoadingOutlined, CaretDownOutlined, CaretRightOutlined } from '@ant-design/icons'

import ChatListItem from './ChatListItem'

const ChatList = (props) => {
  const [chatList, setChatList] = useState(props.chatList)
  const [expandedDepartments, setExpandedDepartments] = useState({})

  useEffect(() => {
    setChatList(props.chatList);
    const initialExpanded = props.chatList.reduce((acc, room) => {
      const deptName = room.department?.name || '未分類';
      acc[deptName] = true;
      return acc;
    }, {});
    setExpandedDepartments(initialExpanded);
  }, [props.chatList]);

  const toggleDepartment = (department, e) => {
    e.stopPropagation();
    setExpandedDepartments(prev => ({
      ...prev,
      [department]: !prev[department]
    }));
  };

  const onChangeTab = (key) => {
    if (parseInt(key) === 0) {
      setChatList(props.chatList)
    } else if (parseInt(key) === 1) {
      setChatList(props.chatList.filter(room => room.creator_id === props.userId))
    } else if (parseInt(key) === 2) {
      setChatList(props.chatList.filter(room => room.creator_id !== props.userId))
    }
  };

  const tabs = [
    {
      key: '0',
      label: '全部',
    },
    {
      key: '1',
      label: '房主',
    },
    {
      key: '2',
      label: '其他',
    },
  ];

  const groupedChatRooms = chatList.reduce((departments, room) => {
    const departmentName = room.department?.name || '未分類';
    departments[departmentName] = departments[departmentName] || [];
    departments[departmentName].push(room);
    return departments;
  }, {});

  return (
    <div className="flex flex-col min-w-72 max-w-72 bg-bgMedium px-5 pt-4 h-screen overflow-hidden">
      <div className="flex flex-row justify-between gap-2">
        <Button type="primary" onClick={props.openCreateModal} className="mt-2 w-1/2">
          創建聊天室
        </Button>
        <Button type="primary" onClick={props.openJoinModal} className="mt-2 w-1/2">
          加入聊天室  
        </Button>
      </div>
      <div className="text-2xl font-bold mb-2 mt-6 text-textDark">
        聊天室
      </div>
      <Tabs defaultActiveKey="0" items={tabs} onChange={onChangeTab} className="flex-none" />
      <div className="flex-1 flex flex-col overflow-hidden relative">
        {
          props.loading ? <div className='flex items-center justify-center w-full h-full'>
            <Spin
              indicator={
                <LoadingOutlined
                  style={{
                    fontSize: 48,
                  }}
                  spin
                />
              }
            />
          </div> :
          chatList.length === 0 ?
          <div className="w-full flex flex-col gap-4 items-center justify-center pt-12">
            這裡沒有任何聊天室。
            <a href="/explore">
              <Button>
                探索聊天室
              </Button>
            </a>
          </div> :
            <>
              <div 
                className="flex flex-col gap-4 overflow-y-auto pb-12 overflow-x-hidden absolute inset-0"
                style={{ WebkitOverflowScrolling: 'touch' }}
              >
                {
                  Object.keys(groupedChatRooms).map((department, index) => {
                    const isDepartmentActive = groupedChatRooms[department].some(room => room.id === props.activeRoomId);

                    return (
                      <div
                        key={index}
                        className={`flex flex-col gap-2 p-2 rounded-xl ${isDepartmentActive ? "bg-primaryLight drop-shadow-md" : ""}`}
                      >
                        <div 
                          className="flex flex-row items-center cursor-pointer font-bold text-textDark"
                          onClick={(e) => toggleDepartment(department, e)}
                        >
                          {expandedDepartments[department] ? (
                            <CaretDownOutlined className="mr-2" />
                          ) : (
                            <CaretRightOutlined className="mr-2" />
                          )}
                          <span>{department}</span>
                          <span className="ml-2 text-sm text-textLight">
                            ({groupedChatRooms[department].length})
                          </span>
                        </div>
                        {expandedDepartments[department] && (
                          <div className="flex flex-col gap-2">
                            {groupedChatRooms[department].map((room, index) => (
                              <div
                                key={index}
                                onClick={() => props.changeActiveRoom(room.id)}
                              >
                                <div className="flex flex-row items-center">
                                  {
                                    room.id === props.activeRoomId &&
                                    <div className={classnames("w-[3px] h-[64px] bg-primary rounded-r-lg absolute z-10 left-0", {
                                      "h-[88px]": room.creator_id === props.userId
                                    })} />
                                  }
                                  <ChatListItem
                                    isActive={room.id === props.activeRoomId}
                                    isOwner={room.creator_id === props.userId}
                                    roomName={room.name}
                                    onlineCount={room.num_of_members}
                                  />
                                </div>
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    )
                  })}
              </div>
            </>
        }
      </div>
    </div>
  )
}

export default ChatList