import { SearchResult } from '../../domain/entities/SearchResult';

export class SearchUseCase {
  constructor(searchRepository) {
    this.searchRepository = searchRepository;
  }

  async executeSearch(chatroomId, keyword, searchRange, limit = 10) {
    try {
      const searchPromises = [];
      
      if (searchRange === '全部搜尋' || searchRange === '一般記憶') {
        searchPromises.push(
          this.searchRepository.searchGeneralMemories(chatroomId, keyword, limit)
            .then(results => results.map(item => SearchResult.fromApiResponse(item, '一般記憶')))
        );
      }

      if (searchRange === '全部搜尋' || searchRange === '進階記憶') {
        searchPromises.push(
          this.searchRepository.searchAdvancedMemories(chatroomId, keyword, limit)
            .then(results => results.map(item => SearchResult.fromApiResponse(item, '進階記憶')))
        );
      }

      if (searchRange === '全部搜尋' || searchRange === '問答記憶') {
        searchPromises.push(
          this.searchRepository.searchQAMemories(chatroomId, keyword, Math.floor(limit / 2))
            .then(results => results.map(item => SearchResult.fromApiResponse(item, '問答記憶')))
        );
      }

      const results = await Promise.all(searchPromises);
      return results.flat();
    } catch (error) {
      console.error('Search use case error:', error);
      throw error;
    }
  }
} 