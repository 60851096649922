/**
 * Get Single Room Use Case
 * 獲取單個聊天室用例
 */
export default class GetSingleRoomUseCase {
  constructor(chatRoomRepository) {
    this.chatRoomRepository = chatRoomRepository;
  }

  /**
   * Execute the use case
   * 執行用例
   * @param {string} roomId 
   * @returns {Promise<ChatRoom>}
   */
  async execute(roomId) {
    if (!roomId) {
      throw new Error('聊天室 ID 不能為空');
    }

    try {
      return await this.chatRoomRepository.getSingleRoom(roomId);
    } catch (error) {
      throw new Error(`獲取聊天室失敗: ${error.message}`);
    }
  }
} 