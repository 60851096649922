import axios from 'axios';
import ChatRoom from '../../domain/entities/chat/ChatRoom';

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

/**
 * ChatRoom Repository Implementation
 * 聊天室資料存取層實現
 */
export default class ChatRoomRepository {
  constructor(token) {
    this.token = token;
  }

  /**
   * Get single chat room by ID
   * 根據 ID 獲取單個聊天室
   * @param {string} roomId 
   * @returns {Promise<ChatRoom>}
   */
  async getSingleRoom(roomId) {
    try {
      const response = await axios.get(`${SERVER_URL}/private/chatrooms/${roomId}`, {
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${this.token}`
        }
      });

      // Map API response to domain entity
      return new ChatRoom(response.data);
    } catch (error) {
      throw new Error(error.response?.data?.detail || '無法獲取聊天室資訊');
    }
  }
} 